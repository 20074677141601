import {lazy} from 'react';
import reduce from 'lodash/reduce';
import IconProfileDefault from '@teladoc/pulse/icons/profile-default.svg';
import IconSuppliesDefault from '@teladoc/pulse/icons/supplies-default.svg';
import IconChartDefault from '@teladoc/pulse/icons/chart-default.svg';
import IconDocumentDefault from '@teladoc/pulse/icons/document-default.svg';
import IconProgramsDefault from '@teladoc/pulse/icons/programs-default.svg';
import IconAuthorizationDefault from '@teladoc/pulse/icons/authorization-default.svg';
import IconSignOutDefault from '@teladoc/pulse/icons/sign-out-default.svg';
import IconGroupDefault from '@teladoc/pulse/icons/group-default.svg';
import IconBloodSugarDefault from '@teladoc/pulse/icons/blood-sugar-default.svg';
import IconBloodPressureDefault from '@teladoc/pulse/icons/blood-pressure-default.svg';
import IconHeartPulseDefault from '@teladoc/pulse/icons/heart-pulse-default.svg';
import IconWeightDefault from '@teladoc/pulse/icons/weight-default.svg';
import IconReminderDefault from '@teladoc/pulse/icons/reminder-default.svg';
import IconFoodDefault from '@teladoc/pulse/icons/food-default.svg';
import programs, {PROGRAM_STATUSES} from '../programs';
import UserUtils from '../user/user-utils';
import {getStore} from '../store';

/* ***********************************************************************************************************
    IF ALTERING THIS FILE, PLEASE ENSURE YOU KEEP THE SITEMAP UPDATED AS WELL (src/sitemap/Sitemap.jsx)
*********************************************************************************************************** */

const {
    bloodSugar, // diabetes
    deviceFlex,
    bloodPressure, // hypertension
    heartFailure,
    behavioralHealth,
    weight,
    advancedWeight,
    comprehensiveWeight,
    prediabetes,
    advancedPrediabetes,
    comprehensivePrediabetes,
    chronicKidneyDisease,
} = programs;
const COMMON_PROGRAM_SLUGS = [
    bloodSugar.slug,
    deviceFlex.slug,
    bloodPressure.slug,
    heartFailure.slug,
    weight.slug,
    advancedWeight.slug,
    comprehensiveWeight.slug,
    prediabetes.slug,
    advancedPrediabetes.slug,
    comprehensivePrediabetes.slug,
    chronicKidneyDisease.slug,
];
const CORE_PROGRAM_SLUGS = [
    bloodSugar.slug,
    bloodPressure.slug,
    weight.slug,
    prediabetes.slug,
];
const {ACTIVE, REGISTERED} = PROGRAM_STATUSES;

// The programSlugs property is used to show a program name on the navigation menu and to avoid giving a user access to a restricted page based on their active programs
// The isRelative property means that its an internal link to this project and should use proper routing

export const NOT_FOUND = '/not-found';

export const programRoutes = [
    {
        id: 'coaching',
        path: '/coaching',
        programSlugs: [...COMMON_PROGRAM_SLUGS],
        navIcon: IconGroupDefault,
        isRelative: true,
        showDecorativeBackground: false,
        component: lazy(() => import('../dashboard/coaching/Coaching')),
        routes: [
            {
                id: 'goals',
                path: '/coaching/goals',
                isRelative: true,
                showDecorativeBackground: false,
                component: lazy(() =>
                    import('../dashboard/coaching/goals/Goals')
                ),
            },
            {
                id: 'coachingSession',
                path: '/coaching/session/:caseId',
                isRelative: true,
                showDecorativeBackground: false,
                component: lazy(() =>
                    import('../dashboard/coaching/sessions/Session')
                ),
            },
            {
                id: 'coachingSessionDetails',
                path: '/coaching/sessiondetails/:id',
                isRelative: true,
                showDecorativeBackground: false,
                component: lazy(() =>
                    import(
                        '../dashboard/home/coaching/ccmengagementupdate/session-details/SessionDetails'
                    )
                ),
            },
        ],
    },
    {
        id: 'bloodSugar',
        path: '/blood-sugar',
        mixpanelEvent: 'bg.dashboard.cta.clicked',
        apptentiveEvent: 'bg.mainnavigation.link.clicked',
        programSlugs: [bloodSugar.slug, deviceFlex.slug],
        isRelative: true,
        showDecorativeBackground: true,
        navIcon: IconBloodSugarDefault,
        component: lazy(() => import('../blood-sugar/BloodSugar')),
        tabbedRoutes: [
            {
                id: 'overview',
                path: '/blood-sugar/overview',
                mixpanelEvent: 'bg.metrics.overview.clicked',
                isRelative: true,
                showDecorativeBackground: true,
                component: lazy(() =>
                    import('../blood-sugar/overview/Overview')
                ),
            },
            {
                id: 'trends',
                path: '/blood-sugar/trends',
                mixpanelEvent: 'bg.metrics.trends.clicked',
                isRelative: true,
                showDecorativeBackground: true,
                component: lazy(() => import('../blood-sugar/trends/Trends')),
            },
            {
                id: 'allLogs',
                path: '/blood-sugar/all-logs',
                mixpanelEvent: 'bg.metrics.logs.clicked',
                isRelative: true,
                showDecorativeBackground: true,
                component: lazy(() =>
                    import('../blood-sugar/all-logs/AllLogs')
                ),
            },
            {
                id: 'summaryReport',
                path: '/blood-sugar/summary-report',
                mixpanelEvent: 'bg.metrics.summary.report.clicked',
                isRelative: true,
                showDecorativeBackground: true,
                component: lazy(() =>
                    import('../blood-sugar/summary-report/SummaryReport')
                ),
            },
        ],
        routes: [
            {
                id: 'friendsAndFamily',
                path: '/blood-sugar/friends-and-family',
                isRelative: true,
                showDecorativeBackground: false,
                component: lazy(() =>
                    import('../blood-sugar/friends-and-family/FriendsAndFamily')
                ),
            },
            {
                id: 'shareFromMeter',
                path: '/blood-sugar/share-from-meter',
                isRelative: true,
                showDecorativeBackground: false,
                component: lazy(() =>
                    import('../blood-sugar/share-from-meter/ShareFromMeter')
                ),
            },
        ],
    },
    {
        id: 'bloodPressure',
        path: '/blood-pressure',
        mixpanelEvent: 'bp.dashboard.cta.clicked',
        apptentiveEvent: 'bp.mainnavigation.link.clicked',
        programSlugs: [
            bloodPressure.slug,
            heartFailure.slug,
            chronicKidneyDisease.slug,
            comprehensivePrediabetes.slug,
            comprehensiveWeight.slug,
        ],
        isRelative: true,
        showDecorativeBackground: true,
        navIcon: IconBloodPressureDefault,
        component: lazy(() => import('../blood-pressure/BloodPressure')),
        tabbedRoutes: [
            {
                id: 'overview',
                path: '/blood-pressure/overview',
                mixpanelEvent: 'bp.metrics.overview.clicked',
                isRelative: true,
                showDecorativeBackground: true,
                component: lazy(() =>
                    import('../blood-pressure/overview/Overview')
                ),
            },
            {
                id: 'trends',
                path: '/blood-pressure/trends',
                mixpanelEvent: 'bp.metrics.trends.clicked',
                isRelative: true,
                showDecorativeBackground: true,
                component: lazy(() =>
                    import('../blood-pressure/trends/Trends')
                ),
            },
            {
                id: 'allLogs',
                path: '/blood-pressure/all-logs',
                mixpanelEvent: 'bp.metrics.logs.clicked',
                isRelative: true,
                showDecorativeBackground: true,
                component: lazy(() =>
                    import('../blood-pressure/all-logs/AllLogs')
                ),
            },
            {
                id: 'summaryReports',
                path: '/blood-pressure/summary-reports',
                mixpanelEvent: 'bp.metrics.summary.reports.clicked',
                isRelative: true,
                showDecorativeBackground: true,
                component: lazy(() =>
                    import('../blood-pressure/summary-reports/SummaryReports')
                ),
            },
        ],
        routes: [
            {
                id: 'personalizedReport',
                path: '/blood-pressure/personalized-report',
                isRelative: true,
                showDecorativeBackground: false,
                component: lazy(() =>
                    import(
                        '../blood-pressure/personalized-report/PersonalizedReport'
                    )
                ),
            },
            {
                id: 'usingYourMonitor',
                path: '/blood-pressure/using-your-monitor',
                isRelative: true,
                showDecorativeBackground: false,
                component: lazy(() =>
                    import(
                        '../blood-pressure/using-your-monitor/UsingYourMonitor'
                    )
                ),
            },
        ],
    },
    {
        id: 'pulse',
        path: '/pulse',
        // mixpanelEvent: 'bp.dashboard.cta.clicked', // TODO: Add mixpanel event
        // apptentiveEvent: 'bp.mainnavigation.link.clicked', // TODO: Add apptentive event
        programSlugs: [heartFailure.slug],
        isRelative: true,
        showDecorativeBackground: true,
        navIcon: IconHeartPulseDefault,
        component: lazy(() => import('../pulse/Pulse')),
        tabbedRoutes: [
            {
                id: 'overview',
                path: '/pulse/overview',
                mixpanelEvent: 'pulse.metrics.overview.clicked',
                isRelative: true,
                showDecorativeBackground: true,
                component: lazy(() => import('../pulse/overview/Overview')),
            },
            {
                id: 'trends',
                path: '/pulse/trends',
                mixpanelEvent: 'pulse.metrics.trends.clicked',
                isRelative: true,
                showDecorativeBackground: true,
                component: lazy(() => import('../pulse/trends/Trends')),
            },
            {
                id: 'allLogs',
                path: '/pulse/all-logs',
                mixpanelEvent: 'pulse.metrics.alllogs.clicked',
                isRelative: true,
                showDecorativeBackground: true,
                component: lazy(() => import('../pulse/all-logs/AllLogs')),
            },
            {
                id: 'summaryReport',
                path: '/pulse/summary-report',
                mixpanelEvent: 'pulse.metrics.summary.reports.clicked',
                isRelative: true,
                showDecorativeBackground: true,
                component: lazy(() =>
                    import('../pulse/summary-report/SummaryReport')
                ),
            },
        ],
    },
    {
        id: 'behavioralHealth',
        path: '/behavioral-health',
        mixpanelEvent: 'bh.dashboard.cta.clicked',
        apptentiveEvent: 'bh.mainnavigation.link.clicked',
        programSlugs: [behavioralHealth.slug],
        isRelative: false,
        showDecorativeBackground: false,
        component: null,
    },
    {
        id: 'weight',
        path: '/weight',
        mixpanelEvent: 'weight.dashboard.cta.clicked',
        apptentiveEvent: 'weight.mainnavigation.link.clicked',
        programSlugs: [
            weight.slug,
            advancedWeight.slug,
            comprehensiveWeight.slug,
            prediabetes.slug,
            advancedPrediabetes.slug,
            comprehensivePrediabetes.slug,
            heartFailure.slug,
            chronicKidneyDisease.slug,
        ],
        isRelative: true,
        showDecorativeBackground: true,
        navIcon: IconWeightDefault,
        component: lazy(() => import('../weight/Weight')),
        tabbedRoutes: [
            {
                id: 'overview',
                path: '/weight/overview',
                mixpanelEvent: 'weight.metrics.overview.clicked',
                isRelative: true,
                showDecorativeBackground: true,
                component: lazy(() => import('../weight/overview/Overview')),
            },
            {
                id: 'trends',
                path: '/weight/trends',
                mixpanelEvent: 'weight.metrics.trends.clicked',
                isRelative: true,
                showDecorativeBackground: true,
                component: lazy(() => import('../weight/trends/Trends')),
            },
            {
                id: 'allLogs',
                path: '/weight/all-logs',
                mixpanelEvent: 'weight.metrics.alllogs.clicked',
                isRelative: true,
                showDecorativeBackground: true,
                component: lazy(() => import('../weight/all-logs/AllLogs')),
            },
            {
                id: 'summaryReport',
                path: '/weight/summary-report',
                mixpanelEvent: 'weight.metrics.summary.reports.clicked',
                isRelative: true,
                showDecorativeBackground: true,
                component: lazy(() =>
                    import('../weight/summary-report/SummaryReport')
                ),
            },
        ],
        routes: [
            {
                id: 'getStarted',
                path: '/weight/get-started',
                isRelative: true,
                showDecorativeBackground: false,
                component: lazy(() =>
                    import('../weight/get-started/GetStarted')
                ),
            },
        ],
    },
    {
        id: 'food',
        path: '/food',
        mixpanelEvent: 'food.dashboard.cta.clicked',
        apptentiveEvent: 'food.mainnavigation.link.clicked',
        programSlugs: COMMON_PROGRAM_SLUGS,
        isRelative: true,
        showDecorativeBackground: true,
        navIcon: IconFoodDefault,
        component: lazy(() => import('../food/Food')),
        tabbedRoutes: [
            {
                id: 'overview',
                path: '/food/overview',
                mixpanelEvent: 'food.metrics.overview.clicked',
                isRelative: true,
                showDecorativeBackground: true,
                component: lazy(() => import('../food/overview/Overview')),
            },
            {
                id: 'insights',
                path: '/food/insights',
                mixpanelEvent: 'food.metric.insights.clicked',
                isRelative: true,
                showDecorativeBackground: true,
                component: lazy(() =>
                    import('../food/food-insights/FoodInsights')
                ),
            },
        ],
        routes: [
            {
                id: 'log',
                path: '/food/log/:id?',
                isRelative: true,
                showDecorativeBackground: false,
                component: lazy(() =>
                    import('../food/food-log-form/FoodLogForm')
                ),
            },
            {
                id: 'intro',
                path: '/food/intro',
                isRelative: true,
                showDecorativeBackground: false,
                component: lazy(() => import('../food/intro/Intro')),
            },
        ],
    },
    {
        id: 'reminder',
        path: '/reminder',
        mixpanelEvent: 'ccm.navigation.click',
        apptentiveEvent: 'reminder.mainnavigation.link.clicked',
        programSlugs: CORE_PROGRAM_SLUGS,
        isRelative: true,
        showDecorativeBackground: true,
        navIcon: IconReminderDefault,
        component: lazy(() => import('../reminder/Reminder')),
        routes: [
            {
                id: 'setReminder',
                path: '/reminder/set/:id?',
                isRelative: true,
                showDecorativeBackground: false,
                component: lazy(() =>
                    import('../reminder/set-reminder/SetReminder')
                ),
            },
        ],
    },
    {
        id: 'kidneyHealth',
        path: '/kidney-health',
        programSlugs: [],
        navIcon: IconGroupDefault,
        isRelative: true,
        showDecorativeBackground: false,
        component: lazy(() => import('../kidney-health/KidneyHealth')),
    },
];

export const coreRoutes = [
    {
        id: 'dashboard',
        path: '/dashboard',
        mixpanelEvent: 'dashboard.cta.clicked',
        programSlugs: [...COMMON_PROGRAM_SLUGS, behavioralHealth.slug],
        isRelative: true,
        showDecorativeBackground: false,
        component: lazy(() => import('../dashboard/Dashboard')),
    },
    ...programRoutes,
];

export const subNavRoutes = [
    {
        id: 'profilePreferences',
        path: '/profile-preferences',
        mixpanelEvent: 'settings.profilepreferences.cta.clicked',
        programSlugs: [],
        isRelative: true,
        showDecorativeBackground: false,
        icon: IconProfileDefault,
        component: lazy(() =>
            import('../profile-preferences/ProfilePreferences')
        ),
        tabbedRoutes: [
            {
                id: 'accountSettings',
                path: '/profile-preferences/account-settings',
                mixpanelEvent: 'profile.account.settings.viewed',
                isRelative: true,
                showDecorativeBackground: false,
                component: lazy(() =>
                    import(
                        '../profile-preferences/account-settings/AccountSettings'
                    )
                ),
            },
            {
                id: 'communications',
                path: '/profile-preferences/communications',
                mixpanelEvent: 'profile.notifications.viewed', // TODO: Update mixpanel event to 'profile.communications.viewed'
                isRelative: true,
                showDecorativeBackground: false,
                component: lazy(() =>
                    import(
                        '../profile-preferences/communications/Communications'
                    )
                ),
            },
            {
                id: 'healthProfile',
                path: '/profile-preferences/health-profile',
                mixpanelEvent: 'profile.health.info.viewed',
                isRelative: true,
                showDecorativeBackground: false,
                component: lazy(() =>
                    import(
                        '../profile-preferences/health-profile/HealthProfile'
                    )
                ),
            },
            {
                id: 'coverage',
                path: '/profile-preferences/coverage',
                mixpanelEvent: 'profile.coverage.viewed',
                isRelative: true,
                showDecorativeBackground: false,
                component: lazy(() =>
                    import('../profile-preferences/coverage/Coverage')
                ),
            },
            {
                id: 'payment',
                path: '/profile-preferences/payment',
                mixpanelEvent: 'profile.payment.viewed',
                isRelative: true,
                showDecorativeBackground: false,
                component: lazy(() =>
                    import('../profile-preferences/payment/Payment')
                ),
            },
        ],
    },
    {
        id: 'orderSupplies',
        path: '/order-supplies',
        mixpanelEvent: 'settings.ordersupplies.cta.clicked',
        programSlugs: [bloodSugar.slug],
        isRelative: true,
        showDecorativeBackground: false,
        icon: IconSuppliesDefault,
        component: lazy(() => import('../order-supplies/OrderSupplies')),
    },
    {
        id: 'reportsAndData',
        path: '/reports-and-data',
        mixpanelEvent: 'settings.summaryreports.cta.clicked',
        programSlugs: COMMON_PROGRAM_SLUGS,
        isRelative: true,
        showDecorativeBackground: false,
        icon: IconChartDefault,
        component: lazy(() => import('../reports-and-data/ReportsAndData')),
    },
    {
        id: 'userGuides',
        path: '/user-guides',
        mixpanelEvent: 'settings.userguides.cta.clicked',
        programSlugs: [
            bloodSugar.slug,
            bloodPressure.slug,
            heartFailure.slug,
            weight.slug,
            advancedWeight.slug,
            comprehensiveWeight.slug,
            prediabetes.slug,
            advancedPrediabetes.slug,
            comprehensivePrediabetes.slug,
            chronicKidneyDisease.slug,
        ],
        isRelative: true,
        showDecorativeBackground: false,
        icon: IconDocumentDefault,
        component: lazy(() => import('../user-guides/UserGuides')),
    },
    {
        id: 'programs',
        path: '/programs',
        mixpanelEvent: 'settings.programs.cta.clicked',
        programSlugs: [],
        isRelative: true,
        showDecorativeBackground: false,
        icon: IconProgramsDefault,
        component: lazy(() => import('../programs/Programs')),
    },
    {
        id: 'authorization',
        path: '/authorization',
        mixpanelEvent: 'settings.authorization.cta.clicked',
        programSlugs: [],
        isRelative: true,
        showDecorativeBackground: false,
        icon: IconAuthorizationDefault,
        component: lazy(() => import('../authorization/Authorization')),
    },
];

export const userActionRoutes = [
    {
        id: 'login',
        path: '/login',
        isRelative: true,
        icon: null,
        component: lazy(() => import('../user/login/Login')),
    },
    {
        id: 'forgotPassword',
        path: '/forgot-password',
        isRelative: true,
        icon: null,
        component: lazy(() => import('../user/forgot-password/ForgotPassword')),
    },
    {
        id: 'resetPassword',
        path: '/reset-password',
        isRelative: true,
        icon: null,
        component: lazy(() => import('../user/reset-password/ResetPassword')),
    },
    {
        id: 'mfa',
        path: '/mfa',
        isRelative: true,
        icon: null,
        component: lazy(() => import('../user/mfa/MFA')),
    },
    {
        id: 'verificationRequired',
        path: '/verification-required',
        isRelative: true,
        icon: null,
        component: lazy(() =>
            import('../user/verification-required/VerificationRequired')
        ),
    },
    {
        id: 'verificationExpired',
        path: '/verification-expired',
        isRelative: true,
        icon: null,
        component: lazy(() =>
            import('../user/verification-expired/VerificationExpired')
        ),
    },
    {
        id: 'rateLimitExceeded',
        path: '/rate-limit-exceeded',
        isRelative: true,
        icon: null,
        component: lazy(() =>
            import('../user/rate-limit-exceeded/RateLimitExceeded')
        ),
    },
    {
        id: 'logout',
        path: '/logout',
        isRelative: true,
        icon: IconSignOutDefault,
        component: null,
    },
    {
        id: 'nonMember',
        path: '/non-member',
        isRelative: true,
        icon: null,
        component: lazy(() =>
            import('../confirm-lab-order/non-member/Recruitments')
        ),
    },
    {
        id: 'campaignAndAccountEnded',
        path: '/non-member/account-ended',
        isRelative: true,
        icon: null,
        component: lazy(() =>
            import('../confirm-lab-order/non-member/CampaignAndAccountEnded')
        ),
    },
    {
        id: 'nonMember-OrderForm',
        path: '/non-member/order-form',
        isRelative: true,
        icon: null,
        component: lazy(() =>
            import('../confirm-lab-order/non-member/NonMembersOrderForm')
        ),
    },
    {
        id: 'nonMember-OrderSuccess',
        path: '/non-member/confirm-lab-order',
        isRelative: true,
        icon: null,
        component: lazy(() =>
            import('../confirm-lab-order/members/OrderCompleted')
        ),
    },
    {
        id: 'migration',
        path: '/migration',
        isRelative: true,
        icon: null,
        component: lazy(() =>
            import('../user/client-migration/MobileMigration')
        ),
    },
    {
        id: 'keycloak',
        path: '/keycloak',
        isRelative: true,
        icon: null,
        component: lazy(() => import('../user/client-migration/Keycloak')),
    },
    {
        id: 'transitionScreen',
        path: '/transitionScreen',
        isRelative: true,
        icon: null,
        component: lazy(() =>
            import('../user/client-migration/transition-screen')
        ),
    },
    {
        id: 'privacy',
        path: '/privacy',
        isRelative: true,
        icon: null,
        component: lazy(() => import('@teladoc/fe-teams/ui/privacy/Privacy')),
    },
    {
        id: 'teamsCoaching',
        path: '/teams-coaching',
        isRelative: true,
        icon: null,
        component: lazy(() => import('@teladoc/fe-teams/ui/coaching/Coaching')),
    },
];

export const commonRoutes = [
    {
        id: 'sitemap',
        path: '/sitemap',
        isRelative: true,
        icon: null,
        component: lazy(() => import('../sitemap/Sitemap')),
    },
];

export const hiddenRoutes = [
    {
        id: 'labOrders',
        path: '/confirm-lab-order/members',
        isRelative: true,
        icon: null,
        component: lazy(() => import('../confirm-lab-order/members/Members')),
    },
    {
        id: 'campaignEnded',
        path: '/campaign-ended',
        isRelative: true,
        icon: null,
        component: lazy(() =>
            import('../confirm-lab-order/shared/CampaignEnded')
        ),
    },
    {
        id: 'confirmAddressMembers',
        path: '/confirm-lab-order/confirm-address',
        isRelative: true,
        icon: null,
        component: lazy(() => import('../confirm-lab-order/members/Members')),
    },
    {
        id: 'confirmOrderMembers',
        path: '/confirm-lab-order',
        isRelative: true,
        icon: null,
        component: lazy(() =>
            import('../confirm-lab-order/members/OrderCompleted')
        ),
    },
    {
        id: 'activateDevices',
        path: '/activate-devices',
        isRelative: true,
        component: lazy(() =>
            import('../launchpad/activate-devices/ActivateDevices')
        ),
    },
];

export function getPrimaryRouteById(id) {
    const match = [
        ...coreRoutes,
        ...subNavRoutes,
        ...userActionRoutes,
        ...hiddenRoutes,
    ].find(route => route.id === id);

    if (!match) {
        throw new Error(`No primary route exists for ${id}.`);
    }

    return match;
}

export function getSecondaryRoute({primaryId, secondaryId}) {
    const {tabbedRoutes = [], routes = []} = getPrimaryRouteById(primaryId);

    const match = [...tabbedRoutes, ...routes].find(
        route => route.id === secondaryId
    );

    if (!match) {
        throw new Error(`No secondary route exists for ${secondaryId}.`);
    }

    return match;
}

export function getCommonRouteById(id) {
    const match = commonRoutes.find(route => route.id === id);

    if (!match) {
        throw new Error(`No common route exists for ${id}.`);
    }

    return match;
}

export function getActiveRoutes(routes) {
    const store = getStore();
    const {
        user: {programs: userPrograms},
        app: {hasKidneyLabResults},
    } = store.getState();

    return reduce(
        routes,
        (result, route) => {
            // behavioralHealth should be available if program status is either ACTIVE or REGISTERED
            const isBehavioralHealth = userPrograms.some(
                ({id, status}) =>
                    id === route.id &&
                    id === behavioralHealth.id &&
                    [ACTIVE, REGISTERED].includes(status)
            );
            const isKidneyHealth =
                route.id === 'kidneyHealth' && hasKidneyLabResults;

            if (route.id === 'kidneyHealth' && !hasKidneyLabResults) {
                // We don't want to show "Kidney Health" on the sidebar if there's no kidney lab results
                return result;
            } else if (
                isBehavioralHealth ||
                isKidneyHealth ||
                UserUtils.willRender(route.programSlugs)
            ) {
                result.push(route);
            }

            return result;
        },
        []
    );
}
