import DOMUtils from '@livongo/utilities/system/dom';

const ApptentiveUtils = {
    async init({appId, options = {}} = {}) {
        await DOMUtils.loadScript({
            src: `https://sdk.apptentive.com/v1/apps/${appId}/websdk`,
        });

        window.ApptentiveSDK.createConversation(options);
    },

    identify({publicUUID, customData}) {
        if (!window.ApptentiveSDK) {
            return;
        }

        window.ApptentiveSDK.identifyPerson({
            /* eslint-disable camelcase */
            unique_token: publicUUID,
            ...(customData && {custom_data: customData}),
            /* eslint-enable camelcase */
        });
    },

    event(eventName) {
        if (!window.ApptentiveSDK) {
            return;
        }

        if (!window.ApptentiveSDK.conversation) {
            return;
        }

        window.ApptentiveSDK.engage(eventName);
    },

    updatePerson(person) {
        if (!window.ApptentiveSDK) {
            return;
        }

        if (!window.ApptentiveSDK.conversation) {
            return;
        }

        window.ApptentiveSDK.updatePerson(person);
    },

    setLocale(locale) {
        if (!window.ApptentiveSDK) {
            return;
        }

        if (!window.ApptentiveSDK.conversation) {
            return;
        }

        window.ApptentiveSDK.setLocale(locale);
    },
};

export default ApptentiveUtils;
