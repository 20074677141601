import PropTypes from 'prop-types';
import {cnb} from 'cnbuilder';
import css from './Card.scss';

const Card = ({
    forwardedRef,
    className,
    bgColor,
    fullExtension,
    children,
    ...rest
}) => (
    <div
        ref={forwardedRef}
        className={cnb(
            css.root,
            {[css[bgColor]]: Boolean(bgColor)},
            {
                [css.fullExtension]: fullExtension,
            },
            className
        )}
        {...rest}
    >
        {children}
    </div>
);

Card.propTypes = {
    forwardedRef: PropTypes.object,
    className: PropTypes.string,
    bgColor: PropTypes.oneOf(['primary']),
    // Extends the card to full browser width on smaller devices
    fullExtension: PropTypes.bool,
    children: PropTypes.node.isRequired,
};

export default Card;
