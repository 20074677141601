import {useState, useRef} from 'react';
import PropTypes from 'prop-types';
import {cnb} from 'cnbuilder';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import notify from '@teladoc/pulse/ui/Notification';
import IconLanguageDefault from '@teladoc/pulse/icons/language-default.svg';
import Button from '@teladoc/pulse/ui/Button';
import Form from '@teladoc/pulse/ui/Form';
import {useI18nContext} from '@teladoc/pulse/ui/Context/i18n';
import HorizontalRule from '@teladoc/pulse/ui/HorizontalRule';
import Modal from '@teladoc/pulse/ui/Modal';
import Select from '@teladoc/pulse/ui/Select';
import FormSaveError from '@teladoc/fe-ccm/ui/common/form-save-error/FormSaveError';
import UserAPI from '@teladoc/fe-ccm/ui/user/user-api';
import {userUpdate, userLogout} from '@teladoc/fe-ccm/ui/user/user-actions';
import MixpanelUtils from '@teladoc/fe-ccm/ui/common/utilities/mix-panel';
import Link from '@teladoc/fe-ccm/ui/router/Link';
import {
    getPrimaryRouteById,
    getActiveRoutes,
    subNavRoutes,
} from '@teladoc/fe-ccm/ui/router/routes';
import CommonUtils from '@teladoc/fe-ccm/ui/common/utilities/common-utils';
import {UTM_PARAMETERS} from '@teladoc/fe-ccm/ui/config';
import useMounted from '@livongo/utilities/hooks/useMounted';
import css from './Navigation.scss';

const logout = getPrimaryRouteById('logout');

const SubNavigation = ({
    className,
    onItemClick,
    itemClassName,
    showLanguageModal,
    activeItemClassName,
    onLanguageModalToggle,
}) => {
    const {scopedLangCodes} = useI18nContext();
    const formRef = useRef();
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {isMounted} = useMounted();
    const [isSaving, setIsSaving] = useState(false);
    const [saveError, setSaveError] = useState(null);
    const {userLocale} = useSelector(state => state.user);
    const activeRoutes = getActiveRoutes(subNavRoutes);
    const onModalToggle = evt => {
        const show = !showLanguageModal;

        if (show && onItemClick) {
            onItemClick(evt);
        } else {
            setSaveError(null);
        }

        onLanguageModalToggle(show);
    };
    const onLanguageSave = async () => {
        const form = formRef.current;
        const {language} = form.submit();

        if (language === userLocale) {
            onLanguageModalToggle(false);

            return;
        }

        setIsSaving(true);
        setSaveError(null);

        try {
            await UserAPI.updateLanguagePreference(language);

            if (isMounted) {
                setIsSaving(false);
                onLanguageModalToggle(false);
                dispatch(userUpdate({userLocale: language}));

                setTimeout(() => {
                    // put a slight delay on this so it doesn't interfere with the re-render of this component
                    notify({
                        type: 'success',
                        title: t(
                            'common:notifications.accountSettings.successTitle'
                        ),
                        message: t(
                            'common:notifications.accountSettings.successMessage'
                        ),
                    });
                }, 1000);
            }
        } catch (error) {
            if (isMounted) {
                setIsSaving(false);
                setSaveError(error);
            }
        }
    };
    const onLogoutClick = evt => {
        evt.preventDefault();

        MixpanelUtils.track({event: 'logout.button.clicked'});

        dispatch(userLogout());
        CommonUtils.removeCookie(UTM_PARAMETERS);

        if (onItemClick) {
            onItemClick(evt, 'logout');
        }
        document
            .querySelector('body')
            ?.classList?.remove('body', 'Sidebar-showing');
    };

    return (
        <nav className={cnb(css.root, className)}>
            <ul>
                {activeRoutes.map(({id, path, icon: Icon}) => {
                    return (
                        <li key={id}>
                            <Link
                                className={cnb(css.navItem, itemClassName)}
                                activeClassName={cnb(
                                    css.navItemActive,
                                    activeItemClassName
                                )}
                                to={path}
                                data-id={id}
                                onClick={onItemClick}
                            >
                                <Icon className={css.navItemIcon} />
                                {t(`header.navigation.${id}`)}
                            </Link>
                        </li>
                    );
                })}
                <HorizontalRule className={css.hr} />
                <li>
                    <Button
                        className={css.navItem}
                        onClick={onModalToggle}
                        block
                    >
                        <IconLanguageDefault className={css.navItemIcon} />
                        {t('header.navigation.language')}
                    </Button>
                </li>
                <HorizontalRule className={css.hr} />
                <li>
                    <Link
                        className={css.navItem}
                        to={logout.path}
                        onClick={onLogoutClick}
                    >
                        <logout.icon className={css.navItemIcon} />
                        {t('header.navigation.logout')}
                    </Link>
                </li>
            </ul>
            <Modal
                isOpen={showLanguageModal}
                title={t('common:modals.languagePreference.title')}
                onRequestModal={onModalToggle}
                onRequestClose={onModalToggle}
                primaryAction={{
                    form: 'languagePreference',
                    type: 'submit',
                    variant: 'primary',
                    loading: isSaving,
                    disabled: isSaving,
                    children: t('common:modals.languagePreference.save'),
                }}
                secondaryAction={{
                    onClick: onModalToggle,
                    children: t('common:modals.languagePreference.cancel'),
                }}
            >
                <Form
                    ref={formRef}
                    id="languagePreference"
                    onSubmit={onLanguageSave}
                >
                    {/* TODO: Move value from "ariaLabel" to "label" when we add a field "unit preference" to this modal */}
                    <Select
                        id="language"
                        name="language"
                        classNameControl={css.language}
                        i18nItemLabel={t(
                            'common:modals.languagePreference.language'
                        )}
                        helpNode={
                            <p className={css.description}>
                                {t(
                                    'common:modals.languagePreference.description'
                                )}
                            </p>
                        }
                        items={scopedLangCodes.map(code => ({
                            label: t(`ui:languages.${code}`),
                            value: code,
                        }))}
                        defaultValue={userLocale}
                    />
                    <FormSaveError error={saveError} />
                </Form>
            </Modal>
        </nav>
    );
};

SubNavigation.propTypes = {
    className: PropTypes.string,
    onItemClick: PropTypes.func,
    itemClassName: PropTypes.string,
    activeItemClassName: PropTypes.string,
    showLanguageModal: PropTypes.bool.isRequired,
    onLanguageModalToggle: PropTypes.func.isRequired,
};

export default SubNavigation;
