import isEmpty from 'lodash/isEmpty';
import APIUtils from '@livongo/utilities/system/api';
import WeightAPI from '../weight/weight-api';
import BloodPressureAPI from '../blood-pressure/blood-pressure-api';
import BloodSugarAPI from '../blood-sugar/blood-sugar-api';
/*
For more details on the API
https://confluence.teladoc.net/pages/viewpage.action?pageId=2976259812
*/

/**
 * LaunchpadAPI object for managing the Launchpad status and related functionalities.
 *
 */
const LaunchpadAPI = {
    /*  get the Launchpad status
        GET v1/users/me/launchpad
        completed: Indicates if all launchpad items are completed
        completionNotified: Indicates if completion of launchpad items is notified (e.g. show confetti etc)
        minimized: Indicates if the launchpad is minimized
        completedItemCount: The count of completed launchpad items
        totalItemCount: The count of total launchpad items
        items: List of items to show on launchpad and if they are completed.
    */
    /**
     * Retrieves the status of the launchpad.
     * @returns {Promise} A promise that resolves with the response from the API.
     */
    getStatus() {
        return APIUtils.get(
            `${process.env.SERVICE_URL}/service-journey-guidance/v1/users/me/launchpad`,
            null
        ).then(response => response);
    },
    /**
     * Updates the status of the launchpad.
     *
     * @param {string} status - The new status to be set.
     * @returns {Promise} - A promise that resolves with the response from the API call.
     */
    patchStatus(status) {
        return APIUtils.patch(
            `${process.env.SERVICE_URL}/service-journey-guidance/v1/users/me/launchpad`,
            status
        ).then(response => response);
    },
    /*  This api will reset all health launchpad status excluding calculated status 
        (all items completed, completed items count, total items count etc.) 
        or items marked completed by kafka. It is for testing only and is disabled on PROD.
    */
    delete() {
        return APIUtils.delete(
            `${process.env.SERVICE_URL}/service-journey-guidance/v1/users/me/launchpad`
        );
    },
    /**
     * Gets weight target.
     *
     * @returns {Promise<boolean>} A promise that resolves to a boolean indicating whether the weight target was successfully activated.
     */
    wtActivated() {
        return WeightAPI.getTarget().then(result => result.selfEnteredTarget);
    },
    /**
     * Gets BG.
     * @returns {Promise<boolean>} A promise that resolves to a boolean indicating if the background is activated.
     */
    bgActivated() {
        return BloodSugarAPI.getReadings({
            start: new Date(0),
            count: 1,
            sortKey: 'time',
        }).then(result => !isEmpty(result));
    },
    /**
     * Gets blood pressure.
     * @returns {Promise<boolean>} A promise that resolves to a boolean indicating if the blood pressure is activated.
     */
    bpActivated() {
        return BloodPressureAPI.getStatistics().then(result => {
            return !isEmpty(result?.readings);
        });
    },
    /**
     * Checks if scale is activated.
     * @param {Object} program - The program object.
     * @returns {Promise<boolean>} - A promise that resolves to a boolean indicating whether the program is activated or not.
     */
    async isWtActivated(program) {
        let result = false;

        try {
            if (program?.WM) {
                result = await this.wtActivated();
            }

            return result;
        } catch (err) {
            return result;
        }
    },
    /**
     * Check BP device is activated.
     * @param {Object} program - The program object.
     * @returns {Promise<boolean>} - A promise that resolves to a boolean indicating whether the HTN is activated or not.
     */
    async isBpActivated(program) {
        let result = false;

        try {
            if (program?.HTN) {
                result = await this.bpActivated();
            }

            return result;
        } catch (err) {
            return result;
        }
    },
    /**
     * Checks BG device is activated.
     * @param {Object} program - The program object.
     * @returns {Promise<boolean>} - A promise that resolves to a boolean indicating if the program is background activated.
     */
    async isBgActivate(program) {
        let result = false;

        try {
            if (program?.DM) {
                result = await this.bgActivated();
            }

            return result;
        } catch (err) {
            return result;
        }
    },
    /**
     * Checks if the devices are activated.
     * @param {Array} programs - The list of programs to check activation for.
     * @returns {Object|null} - An object containing the activation status for WM, BP, and BG devices, or null if an error occurred.
     */
    async checkDevicesActivated(programs) {
        try {
            const res = await Promise.all([
                this.isWtActivated(programs),
                this.isBpActivated(programs),
                this.isBgActivate(programs),
            ]);
            const [WT, BP, BG] = res;

            return {
                WT,
                BP,
                BG,
            };
        } catch (err) {
            return null;
        }
    },
};

export default LaunchpadAPI;
