import React from 'react';

export const ReactAxeCore = async ({loadScript}) => {
    if (loadScript && process.env.ENVIRONMENT !== 'production') {
        try {
            const axe = await import('@axe-core/react');
            const ReactDOM = await import('react-dom');

            const config = {
                tagExclude: ['best-practice'],
                disableDeduplicate: true,
            };

            axe.default(React, ReactDOM, 1000, config);
        } catch (error) {
            // eslint-disable-next-line no-console
            console.warn('Failed to load @axe-core/react:', error);
        }
    }
};
export default ReactAxeCore;
