// The below imports are installed as a dependency of @livongo protobuf packages and don't need to be managed in our project directly
/* eslint-disable node/no-extraneous-import */
import {Empty} from 'google-protobuf/google/protobuf/empty_pb';
import {FieldMask} from 'google-protobuf/google/protobuf/field_mask_pb';
import {Timestamp} from 'google-protobuf/google/protobuf/timestamp_pb';
import {
    BoolValue,
    Int32Value,
    StringValue,
    FloatValue,
    Int64Value,
} from 'google-protobuf/google/protobuf/wrappers_pb';
/* eslint-enable node/no-extraneous-import */
import {
    ChatType,
    ChatSetupModeratorPromiseClient,
    CreateOneOnOneChatRequest,
    CreateRequestCoachRequest,
    GetSendBirdUserCredentialsRequest,
} from '@livongo/protobuf-grpc-external-chat';
import {
    ContentStatus,
    GetCourseSummaryRequest,
    GetDesiredCourseSummaryRequest,
    GetEducationServiceInfoRequest,
    RenderingPromiseClient,
    UserManagerPromiseClient,
    UserActivityPromiseClient,
    GetVideoStatusRequest,
} from '@livongo/protobuf-grpc-external-education';
import {
    BiometricInfoPromiseClient,
    GetBiometricFeedbackRequest,
} from '@livongo/protobuf-grpc-external-biometric_alerts/lib/livongo/protobuf/grpc/external/biometric_alerts/biometricInfo_grpc_web_pb';
import {
    Label as LabelStatus,
    AlertState,
    AlertResult,
    AlertType,
    QuestionAnswer,
} from '@livongo/protobuf-grpc-external-biometric_alerts/lib/livongo/protobuf/common/user/biometricAlerts_pb';
import {
    AlertFlowPromiseClient,
    GetAlertStageRequest,
    UpdateAlertStageRequest,
    ListFlowStagesRequest,
} from '@livongo/protobuf-grpc-external-biometric_alerts/lib/livongo/protobuf/grpc/external/biometric_alerts/alertFlow_grpc_web_pb';
import {
    CreateFoodLogRequestNonStreaming,
    DeleteFoodLogRequest,
    FoodLogCreation,
    FoodLogServicePromiseClient,
    GetFoodLogHistoryRequest,
    MealTag,
    UpdateFoodLogRequest,
    GetExternalUserIdRequest,
    ListRecentFoodsRequest,
    GetFoodLogByIdRequest,
    NixFood,
    FoodType,
    GetFocusSetupVisibilityRequest,
    UpdateFocusSetupViewStatusRequest,
    GetRecentMealsByTagRequest,
    UpsertMealRequest,
    Meal,
} from '@livongo/protobuf-grpc-external-food/lib/livongo/protobuf/grpc/external/food/food_grpc_web_pb';
import {
    FoodFocusServicePromiseClient,
    MetricStatus,
    GetNutritionSummariesRequest,
    ListFoodLogFocusesRequest,
    SaveFoodLogFocusRequest,
    DeleteFoodLogFocusRequest,
    GenerateCalorieTargetRequest,
    GetUserHeightWeightInfoRequest,
    FoodLogFocus,
    FoodLogOption,
    FoodLogTarget,
} from '@livongo/protobuf-grpc-external-food/lib/livongo/protobuf/grpc/external/food/focus_grpc_web_pb';
import {
    CoachingSessionManagerPromiseClient,
    ListMemberGoalsRequest,
    ListCoachingSessionsRequest,
    GetCoachingSessionDetailsRequest,
    CoachingStatusManagerPromiseClient,
    GetCoachingFlowStatusRequest,
    GetMemberUpcomingSessions,
    UpdateCoachingFlowStatusRequest,
    CoachingFlowStatus,
    CoachingFlowName,
    GetMemberUpcomingSessionRequest,
} from '@livongo/protobuf-grpc-external-coaching';
import {ImageMetadata} from '@livongo/protobuf-common-meta/lib/livongo/protobuf/common/meta/image_pb';
import {AbsoluteTimestamp} from '@livongo/protobuf-common-types/lib/livongo/protobuf/common/types/absoluteTimestamp_pb';
import {
    DiagnosisPromiseClient,
    admin as DiagnosesAndProgramsRequests,
} from '@livongo/protobuf-grpc-external-enrollment/lib/livongo/protobuf/grpc/external/enrollment/diagnosis_api_grpc_web_pb';
import {DiagnosisType} from '@livongo/protobuf-grpc-external-enrollment/lib/livongo/protobuf/common/user/diagnosis_pb';
import {
    Program as ProgramType,
    ProgramStatus,
} from '@livongo/protobuf-grpc-external-enrollment/lib/livongo/protobuf/common/user/program_pb';
import {
    UserIdType,
    UserId,
} from '@livongo/teladoc-protobuf-common-user-identity/lib/teladoc/protobuf/common/user/identity/userId_pb';
import {
    CommsPreferenceServicePromiseClient,
    GetCommsPreferenceRequest,
    GetOptOutRecordRequest,
    UpdateFeaturePreferenceRequest,
    UpdatePhoneNumberGroupPreferenceRequest,
} from '@livongo/teladoc-protobuf-grpc-comms/lib/teladoc/protobuf/grpc/comms/commsPreference_grpc_web_pb';
import {
    ChannelState,
    FeaturePreference,
    FeatureState,
    PhoneNumberGroupPreference,
    State,
} from '@livongo/teladoc-protobuf-grpc-comms/lib/teladoc/protobuf/common/user/communication/preference_pb';
import {
    Channel,
    Feature,
    PhoneNumberGroup,
} from '@livongo/teladoc-protobuf-grpc-comms/lib/teladoc/protobuf/common/user/communication/comms_pb';
import {Date} from '@livongo/protobuf-grpc-external-enrollment/lib/livongo/protobuf/common/types/date_pb';
import {
    FlowAdminServicePromiseClient,
    GetFlowStatusRequest,
    CreateTeladocSdkInfoRequest,
} from '@livongo/protobuf-grpc-external-med_opt/lib/livongo/protobuf/grpc/external/med_opt/flow_admin_grpc_web_pb';
import {OneAppMigrationPromiseClient} from '@livongo/protobuf-grpc-external-enrollment/lib/livongo/protobuf/grpc/external/enrollment/oneapp_migration_api_grpc_web_pb';
import {GetOneAppMigrationInfoRequest} from '@livongo/protobuf-grpc-external-enrollment/lib/livongo/protobuf/grpc/external/enrollment/oneapp_migration/migration_info_pb';
import UserAPI from '../../user/user-api';

const SERVICE_URL = process.env.SERVICE_URL;
const ChatClient = new ChatSetupModeratorPromiseClient(SERVICE_URL);
const ProgramClient = new DiagnosisPromiseClient(SERVICE_URL);
const FoodLogClient = new FoodLogServicePromiseClient(SERVICE_URL);
const FoodFocusClient = new FoodFocusServicePromiseClient(SERVICE_URL);
const RenderingClient = new RenderingPromiseClient(SERVICE_URL);
const UserClient = new UserManagerPromiseClient(SERVICE_URL);
const UserActivityClient = new UserActivityPromiseClient(SERVICE_URL);
const BiometricClient = new BiometricInfoPromiseClient(SERVICE_URL);
const AlertFlowClient = new AlertFlowPromiseClient(SERVICE_URL);
const CoachingSessionManagerClient = new CoachingSessionManagerPromiseClient(
    SERVICE_URL
);
const CommsPreferenceServiceClient = new CommsPreferenceServicePromiseClient(
    SERVICE_URL
);
const FlowAdminServiceClient = new FlowAdminServicePromiseClient(SERVICE_URL);
let headers = {};
const OneAppMigrationClient = new OneAppMigrationPromiseClient(SERVICE_URL);
const CoachingStatusManagerClient = new CoachingStatusManagerPromiseClient(
    SERVICE_URL
);

if (process.env.ENVIRONMENT !== 'production') {
    // eslint-disable-next-line
    const enableDevTools = window.__GRPCWEB_DEVTOOLS__ || (() => {});

    // enable debugging grpc calls
    enableDevTools([
        BiometricClient,
        ChatClient,
        FlowAdminServiceClient,
        FoodLogClient,
        FoodFocusClient,
        ProgramClient,
        RenderingClient,
        UserClient,
        UserActivityClient,
        CoachingSessionManagerClient,
        OneAppMigrationClient,
        AlertFlowClient,
        CoachingStatusManagerClient,
    ]);
}

const {GetDiagnosesAndProgramsResponse: GetProgramsResponse} =
    DiagnosesAndProgramsRequests;
const ProtoUtils = {
    Chat: {
        ChatClient,
        ChatType,
        CreateOneOnOneChatRequest,
        CreateRequestCoachRequest,
        GetSendBirdUserCredentialsRequest,
    },
    Coaching: {
        CoachingSessionManagerClient,
        ListMemberGoalsRequest,
        ListCoachingSessionsRequest,
        GetCoachingSessionDetailsRequest,
        GetCoachingFlowStatusRequest,
        GetMemberUpcomingSessions,
        CoachingStatusManagerClient,
        UpdateCoachingFlowStatusRequest,
        CoachingFlowStatus,
        CoachingFlowName,
        GetMemberUpcomingSessionRequest,
    },
    Program: {
        ProgramType,
        ProgramStatus,
        DiagnosisType,
        ProgramClient,
        GetProgramsResponse,
    },
    Education: {
        ContentStatus,
        GetCourseSummaryRequest,
        GetDesiredCourseSummaryRequest,
        GetEducationServiceInfoRequest,
        RenderingClient,
        UserClient,
        UserActivityClient,
        GetVideoStatusRequest,
    },
    Food: {
        Date,
        AbsoluteTimestamp,
        CreateFoodLogRequestNonStreaming,
        DeleteFoodLogRequest,
        FoodLogClient,
        FoodFocusClient,
        FoodLogCreation,
        GetFoodLogHistoryRequest,
        ImageMetadata,
        MealTag,
        UpdateFoodLogRequest,
        GetExternalUserIdRequest,
        ListRecentFoodsRequest,
        GetFoodLogByIdRequest,
        NixFood,
        FoodType,
        MetricStatus,
        GetNutritionSummariesRequest,
        GetFocusSetupVisibilityRequest,
        UpdateFocusSetupViewStatusRequest,
        ListFoodLogFocusesRequest,
        SaveFoodLogFocusRequest,
        DeleteFoodLogFocusRequest,
        GenerateCalorieTargetRequest,
        GetUserHeightWeightInfoRequest,
        FoodLogFocus,
        FoodLogOption,
        FoodLogTarget,
        GetRecentMealsByTagRequest,
        UpsertMealRequest,
        Meal,
    },
    Biometric: {
        LabelStatus,
        BiometricClient,
        GetBiometricFeedbackRequest,
        AlertFlowClient,
        GetAlertStageRequest,
        UpdateAlertStageRequest,
        AlertState,
        AlertResult,
        QuestionAnswer,
        ListFlowStagesRequest,
    },
    CommsPreferences: {
        CommsPreferenceServiceClient,
        GetCommsPreferenceRequest,
        GetOptOutRecordRequest,
        UpdateFeaturePreferenceRequest,
        UpdatePhoneNumberGroupPreferenceRequest,
        ChannelState,
        FeaturePreference,
        FeatureState,
        PhoneNumberGroupPreference,
        State,
        Channel,
        Feature,
        PhoneNumberGroup,
    },
    Identity: {
        UserId,
        UserIdType,
    },
    MedOpt: {
        AlertState,
        AlertType,
        GetFlowStatusRequest,
        FlowAdminServiceClient,
        CreateTeladocSdkInfoRequest,
    },
    Google: {
        BoolValue,
        Empty,
        FieldMask,
        Int32Value,
        Timestamp,
        StringValue,
        FloatValue,
        Int64Value,
    },
    Migration: {
        OneAppMigrationClient,
        GetOneAppMigrationInfoRequest,
    },
    // list of gRPC defined status codes https://grpc.github.io/grpc/core/md_doc_statuscodes.html
    STATUSES: {
        OK: 0,
        CANCELLED: 1,
        UNKNOWN: 2,
        INVALID_ARGUMENT: 3,
        DEADLINE_EXCEEDED: 4,
        NOT_FOUND: 5,
        ALREADY_EXISTS: 6,
        PERMISSION_DENIED: 7,
        RESOURCE_EXHAUSTED: 8,
        FAILED_PRECONDITION: 9,
        ABORTED: 10,
        OUT_OF_RANGE: 11,
        UNIMPLEMENTED: 12,
        INTERNAL: 13,
        UNAVAILABLE: 14,
        DATA_LOSS: 15,
        UNAUTHENTICATED: 16,
    },

    getHeaders() {
        return {
            ...headers,
            Authorization: `Bearer ${UserAPI.getCurrentAccessToken()}`,
        };
    },
    setHeaders(attributes) {
        headers = {
            ...headers,
            ...attributes,
        };
    },
};

export default ProtoUtils;
