import isEmpty from 'lodash/isEmpty';
import ProtoUtils from '../../../common/utilities/proto-utils';
import programs from '../../../programs';
import CurriculumUtils from './curriculum-utils';

const {
    Education: {
        GetCourseSummaryRequest,
        GetDesiredCourseSummaryRequest,
        GetEducationServiceInfoRequest,
        RenderingClient,
        UserClient,
        UserActivityClient,
        GetVideoStatusRequest,
    },
    Google: {Empty, StringValue},
    Program: {ProgramType},
    getHeaders,
} = ProtoUtils;

const CurriculumAPI = {
    getCourse({enableGlpEducation, activePrograms}) {
        const surfacedProgram =
            CurriculumUtils.selectProgramToSurface(activePrograms);
        const preferredProgram = programs[surfacedProgram].dbSlug;

        if (!ProgramType.Values[preferredProgram]) {
            return;
        } else {
            let courseRequest;

            if (enableGlpEducation) {
                courseRequest = new GetDesiredCourseSummaryRequest();
                courseRequest.setProgram(ProgramType.Values[preferredProgram]);
            } else {
                courseRequest = new GetCourseSummaryRequest();
                courseRequest.setCurriculumId(1); // TODO: get curriculumId from user data or URL?
            }

            courseRequest.setTimeZone(
                new StringValue().setValue(
                    Intl.DateTimeFormat().resolvedOptions().timeZone
                )
            );

            if (enableGlpEducation) {
                return RenderingClient.getDesiredCourseSummary(
                    courseRequest,
                    getHeaders()
                );
            }

            return RenderingClient.getCourseSummary(
                courseRequest,
                getHeaders()
            );
        }
    },
    getUrl({enableGlpEducation, activePrograms}) {
        if (isEmpty(activePrograms)) {
            return;
        }

        if (!enableGlpEducation) {
            return UserClient.getServiceInfo(new Empty(), getHeaders());
        }

        const surfacedProgram =
            CurriculumUtils.selectProgramToSurface(activePrograms);
        const preferredProgram = programs[surfacedProgram]?.dbSlug;
        const program = ProgramType.Values[preferredProgram];

        if (!program) {
            return;
        }

        const serviceInfoRequest = new GetEducationServiceInfoRequest();

        serviceInfoRequest.setProgram(program);

        return UserClient.getEducationServiceInfo(
            serviceInfoRequest,
            getHeaders()
        );
    },

    getVideoWatchedStatus() {
        return UserActivityClient.getVideoStatus(
            new GetVideoStatusRequest(),
            getHeaders()
        ).then(response => response.getVideoStatusList());
    },
};

export default CurriculumAPI;
