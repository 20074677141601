import {useEffect, useState} from 'react';
import {useTransLoader} from '@teladoc/fe-i18n';
import {useDispatch} from 'react-redux';
import UserUtils from '@teladoc/fe-ccm/ui/user/user-utils';
import {appUpdate} from '@teladoc/fe-ccm/ui/app/app-actions';
import {userLogout} from '@teladoc/fe-ccm/ui/user/user-actions';
import Arg from '@livongo/arg';
import StorageUtils from '@livongo/utilities/system/storage';
import CommonUtils from '../../common/utilities/common-utils';
import Loader from '../../loader/Loader';
import TeladocSSOForm from '../../teladoc-sso-form/TeladocSSOForm';
import UserAPI from '../user-api';
import MixpanelUtils from '../../common/utilities/mix-panel';
import {KEYCLOAK_URI} from '../../config';

const MobileMigration = () => {
    const {t} = useTransLoader('user');
    const dispatch = useDispatch();
    const partnerId = process.env.TELADOC_PARTNER_ID;
    const isMobile = Arg('fromMobile');
    const accessCode = Arg('authCode');
    const teladocEODUrl = Arg('teladocEOD');
    const sessionUUID = Arg('sessionUuid');
    const locale = Arg('locale');
    const clientName = Arg('clientName');
    const [teladocSSOData, setTeladocSSOData] = useState(null);
    const [dataFetchingComplete, setDataFetchingComplete] = useState(false);
    const [showLoader, setShowLoader] = useState(true);

    useEffect(() => {
        if (isMobile) {
            const fetchData = async () => {
                // Create a FormData object and append accessCode as form data
                const formData = new FormData();

                formData.append('accessCode', accessCode);
                MixpanelUtils.track({
                    event: 'mobile.migrationflow.triggered',
                    properties: {
                        'Session UUID': sessionUUID,
                    },
                });
                StorageUtils.set({
                    key: 'mobile_sessionUuid',
                    value: sessionUUID,
                });

                try {
                    const {ssoStatus, uri, parameters, livongoUuid} =
                        await UserAPI.getMobileRedirectData({
                            partnerId,
                            data: formData,
                            config: {
                                params: {
                                    acsURL: teladocEODUrl,
                                    fromMobile: isMobile,
                                },
                            },
                        });

                    const {ESTABLISHED, FAILURE} = UserUtils.SSO_STATUS;

                    if (ssoStatus === ESTABLISHED && uri && livongoUuid) {
                        MixpanelUtils.track({
                            event: 'ssoStatus.established.successfully',
                            properties: {
                                'Session UUID': sessionUUID,
                            },
                        });
                        // Store URI in cookie
                        CommonUtils.setCookie({
                            key: KEYCLOAK_URI,
                            value: uri,
                        });

                        // Setting mobileUUID and query parameters received from the mobile URL in local storage
                        StorageUtils.set({
                            key: 'mobileUUID',
                            value: livongoUuid,
                        });
                        StorageUtils.set({
                            key: 'mobile_clientName',
                            value: clientName,
                        });
                        StorageUtils.set({
                            key: 'mobile_locale',
                            value: locale,
                        });

                        setTeladocSSOData({uri, parameters});

                        // Only set dataFetchingComplete to true if there is no error
                        setDataFetchingComplete(true);
                        // Set showLoader to false when data fetching is complete
                        setShowLoader(false);
                    } else if (ssoStatus === FAILURE) {
                        MixpanelUtils.track({
                            event: 'ssoStatus.failed',
                            properties: {
                                'Session UUID': sessionUUID,
                            },
                        });
                        throw new Error();
                    }
                } catch (error) {
                    MixpanelUtils.track({
                        event: 'error.userisgetting.loggedout',
                        properties: {
                            'Session UUID': sessionUUID,
                        },
                    });
                    dispatch(userLogout());
                    dispatch(appUpdate({hasTeladocSSOError: true}));
                    setDataFetchingComplete(false);
                }
            };

            fetchData();
        }
    }, [
        isMobile,
        accessCode,
        partnerId,
        teladocEODUrl,
        clientName,
        locale,
        sessionUUID,
        dispatch,
    ]);

    return (
        <>
            {showLoader && (
                <Loader loadingText={t('transitionScreen.loadingText')} />
            )}

            {dataFetchingComplete && teladocSSOData && (
                <TeladocSSOForm data={teladocSSOData} />
            )}
        </>
    );
};

export default MobileMigration;
