import StorageUtils from '@livongo/utilities/system/storage';
import EnvironmentUtils from '@livongo/utilities/system/environment';
import {getStore} from '../../store';
import {sessionUUID} from '../../config';
import CurriculumAPI from '../../dashboard/home/curriculum/curriculum-api';
import CurriculumUtils from '../../dashboard/home/curriculum/curriculum-utils';
import {getDomainName} from './url-utils';
/**
 * Utilities for working with the Document Object Model (DOM).
 * @module CommonUtils
 */
const userAgent = (
    (window.navigator && window.navigator.userAgent) ||
    ''
).toLowerCase();

const CommonUtils = {
    /**
     * Determines whether the code is being executed in Microsoft Internet Explorer.
     * @static
     * @function isIE
     * @param {Number|String} [range] - The version range to test against.
     * @param {String} [ua=navigator.userAgent] - The user agent to test against.
     * @example
     * CommonUtils.isIE();
     * @returns {Boolean} - Whether the environment is Microsoft Internet Explorer.
     */
    isIE(ua = userAgent) {
        const match = ua.match(/(?:msie |trident.+?; rv:)(\d+)/);

        return match !== null;
    },

    /**
     * Convert unicode \uD83C\uDF4E\uD83C\uDF4E\uD83C\uDF4E\uD83C\uDF4E\uD83C\uDF49\uD83C\uDF49\uD83C\uDF49\uD83C\uDF47\uD83C\uDF47\uD83D\uDE0A\uD83D\uDE0A\uD83D\uDE0A to characters like emoji 🍎🍎🍎🍎🍉🍉🍉🍇🍇😊😊😊.
     * @static
     * @function unicodeToChar
     * @param {String} text - The unicode characters to convert.
     * @example
     * CommonUtils.unicodeToChar('\uD83C\uDF4E\uD83C\uDF4E');
     * @see {@link https://stackoverflow.com/a/53863278/1243617|StackOverflow answer}
     * @returns {String} - The resulting emoji characters.
     */
    unicodeToChar(text) {
        return text.replace(/\\u[\dA-F]{4}/gi, match => {
            return String.fromCharCode(parseInt(match.replace(/\\u/g, ''), 16));
        });
    },

    /**
     * Format phone number from 8008675390 format to (800) 867-5390
     * @static
     * @function formatPhoneNumber
     * @param {String} text - The phone number in string format, 12 characters long (10 digits + "+1" to handle for international members), with no formatting applied
     * @example
     * CommonUtils.formatPhoneNumber(+18003421234);
     * @returns {String} - (800) 342-1234 // The number formatted
     */
    formatPhoneNumber(phoneNumber) {
        if (phoneNumber.length !== 12 || typeof phoneNumber !== 'string') {
            // For now, this function only works on 10 character long, standard US/Canada phone numbers
            return null;
        }

        return `(${phoneNumber.substring(2, 5)}) ${phoneNumber.substring(
            5,
            8
        )}-${phoneNumber.substring(8)}`;
    },
    /**
     * Checks to see if a feature in the feature flag list is enabled
     * @static
     * @function isFeatureEnabled
     * @param {Array} - The list of feature objects from context
     * @param {String} - The target name of feature in string format
     * @param {Boolean} - Check current application is oneApp
     * @example
     * CommonUtils.isFeatureEnabled(...,...,..);
     * @returns boolean
     */
    isFeatureEnabled(featureList, featureName, isOneApp) {
        return featureList?.some(
            feature =>
                feature.name === featureName &&
                ((!isOneApp && feature.legacyActive) ||
                    (isOneApp && feature.oneAppActive))
        );
    },
    /**
     * Generates OneApp params for EODs
     * @static
     * @function generateOneAppParams
     * @param {String} - The return path
     * @example
     * CommonUtils.generateOneAppParams('/account/profile');
     * @returns string - The resulting parameters
     */
    generateOneAppParams(returnPath) {
        const host = window.location.hostname;
        let subDomain;

        if (process.env.ENVIRONMENT === 'production') {
            // host is my.teladoc.com and we want to remove 'teladoc.com'
            subDomain = 'my';
        } else {
            subDomain = host;
        }

        return `&oneapp=true&returnSubDomain=${subDomain}&returnPath=${returnPath}`;
    },
    /**
     * Generates a EOD URL for member migration.
     * @static
     * @function generateNewUrl
     * @param {string} - The original URI retrieved from storage.
     * @example
     * CommonUtils.generateNewUrl(uri);
     * @returns string - the new url
     */
    generateNewUrl(uri) {
        // Find the index of the unwanted portion Use slice to remove
        const newUri = uri
            .split('/')
            .slice(0, uri.split('/').indexOf('auth'))
            .join('/');

        // Replace "keycloak" with "mobile-api" in the new URI
        const newBaseDomain = newUri.replace('keycloak', 'mobile-api');
        const newUrl = `${newBaseDomain}/member_migration/start`;

        return newUrl;
    },

    removeLocalStorage(key) {
        StorageUtils.remove({
            key,
            type: 'local',
            useNative: true,
            parse: false,
        });
    },

    getLocalStorage(key) {
        return StorageUtils.get({
            key,
            type: 'local',
            useNative: true,
            parse: false,
            stringify: true,
        });
    },

    setLocalStorage({key, value}) {
        StorageUtils.set({
            key,
            value,
            type: 'local',
            useNative: true,
            parse: false,
            stringify: false,
        });
    },

    /** 
     This function gets a cookie by using its name (key)
     * @static
     * @function getCookie
     * @param {string} - The key (name) of the cookie
     * @example
    * CommonUtils.getCookie({key});
     * @returns nothing -- but gets a cookie!
    */

    getCookie({key}) {
        return StorageUtils.get({
            key,
            type: 'cookie',
            useNative: true,
            parse: false,
        });
    },

    /** 
     This function sets a cookie by using its name (key) and value
     * @static
     * @function setCookie
     * @param {string} - The key (name) of the cookie
     * @param {string} - The cookie's value
     * @example
    * CommonUtils.setCookie({key, value});
     * @returns nothing -- but sets a cookie!
    */
    setCookie({key, value}) {
        StorageUtils.set({
            key,
            value,
            type: 'cookie',
            useNative: true,
            parse: false,
            cookieOptions: {
                domain: getDomainName(),
                sameSite: 'none',
                secure: true,
            },
            stringify: false,
        });
    },

    /** 
     This function removes a cookie by using its name (key)
     * @static
     * @function removeCookie
     * @param {string} - The key (name) of the cookie     
     * @returns nothing -- but sets a cookie!
    */
    removeCookie(key) {
        StorageUtils.remove({
            key,
            type: 'cookie',
            useNative: true,
            parse: false,
        });
    },

    /** 
     This function gets a session_uuid 
     * @static
     * @function getSessionUUID
     * @example
    * CommonUtils.getSessionUUID();
     * @returns nothing -- but gets a session_uuid!
    */
    getSessionUUID() {
        return StorageUtils.get({
            key: sessionUUID,
        });
    },

    /** 
     This function sets a session_uuid by using its name (uuid) and value
     * @static
     * @function setSessionUUID
     * @param {string} - The uuid (name) of the session
     * @param {string} - The uuidvalue
     * @example
    * CommonUtils.setSessionUUID(uuid);
     * @returns nothing -- but sets a session_uuid!
    */

    setSessionUUID(uuid) {
        StorageUtils.set({
            key: sessionUUID,
            value: uuid,
        });
    },

    /** 
     This function returns the current environment of the app.
    If the user is running CCM inside of Teams, it'll return "Teams"
    (Likewise, if it's running CCM features inside of Oneapp, it'll return "oneapp")
     * @static
     * @function getEnvironment
     * @example
    * CommonUtils.getEnvironment();
     * @returns a string - either "oneapp", "teams", "ccm"
    */
    getEnvironment() {
        const store = getStore();
        const {
            app: {isOneApp, isTeamsEnvironment},
        } = store.getState();

        if (isTeamsEnvironment) {
            return 'teams';
        } else if (isOneApp) {
            return 'oneapp';
        } else return 'ccm';
    },

    /** 
     This function returns the image url from src / Cloundinary source ID.
     * @static
     * @function createCloudinaryUrl
     @param src: PropTypes.string, -- Cloudinary source ID 
     * The width of the image.  
     @param width: PropTypes.number,
     * The height of the image. 
     @param  height: PropTypes.number,
     * The quality setting if this image uses a Cloudinary source ID. 
     @param  quality: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(['auto'])]),
     * The format of the image if th is image uses a Cloudinary source ID. See <a href="https://cloudinary.com/documentation/image_transformations#supported_image_formats">supported image formats</a>. 
     @param format: PropTypes.oneOf([
        'auto',
        'gif',
        'webp',
        'jpg',
        'jpe',
        'jpeg',
        'jp2',
        'png',
        'svg',
    ]),
     * @example
     * CommonUtils.createCloudinaryUrl({
                    src: '/member-portal/reminder/bell_onjl8i',
                    width: 116,
                    height: 116,
                })
     * @returns url
     */
    createCloudinaryUrl({
        src,
        width,
        height,
        quality = 'auto',
        format = 'auto',
    }) {
        return `https://assets.livongo.com/image/upload/dpr_${
            EnvironmentUtils.isHighDensityDisplay() ? 2 : 1
        },w_${width},h_${height},q_${quality},f_${format}/${src}`;
    },
    /** 
     This function opens a new tab to the Coaching app for members to schedule a
     coaching session.     
    */
    openCoaching({
        accessCode,
        isOneApp,
        hasChatAvailable,
        enableNewCoachingUi,
        oneAppProfilePath,
        coachingUrl,
        uuid,
        userLocale,
    }) {
        let oneAppParams = '';
        const accessCodeString = accessCode
            ? `&accesscode=${accessCode}&web=true`
            : '';

        if (isOneApp && hasChatAvailable) {
            oneAppParams = CommonUtils.generateOneAppParams(oneAppProfilePath);
        }

        const enableNewUi = enableNewCoachingUi ? '&enableNewUi=true' : '';

        const url = `${coachingUrl}?u=${uuid}&locale=${userLocale}${accessCodeString}${
            isOneApp ? oneAppParams : ''
        }${enableNewUi}`;

        window.open(url);
    },

    getFaviconUrlForBrand({branding, width, height}) {
        if (branding && branding?.brand_code) {
            return this.createCloudinaryUrl({
                src: `pli/brands/${branding.brand_code}/favicon.ico`,
                width,
                height,
            });
        }
    },
    /**
     * This function extracts the country code from a locale string.
     *
     * @param {string} value - The locale string, typically in the format "language-country" (e.g., "en-US").
     * @returns {string} - The country code extracted from the locale string.
     */
    getCountry(value) {
        // If the input value is empty, null, or undefined, return 'USA'
        if (!value) {
            return 'USA';
        }

        // Find the index of the hyphen character in the locale string
        const hyphenIndex = value?.indexOf('-');

        // If a hyphen is found, extract the substring after the hyphen
        if (hyphenIndex !== -1) {
            const country = value.substring(hyphenIndex + 1);

            // Return 'USA' if the country code is 'US', otherwise return the country code
            return country === 'US' ? 'USA' : country;
        }

        // If no hyphen is found in the locale string, default to 'USA'
        return 'USA';
    },

    async openEducationPortal({enableGlpEducation, activePrograms, isOneApp}) {
        const loadedUrl = await CurriculumAPI.getUrl({
            enableGlpEducation,
            activePrograms,
        });

        const surfacedProgram =
            CurriculumUtils.selectProgramToSurface(activePrograms);

        const urlWithParams = CurriculumUtils.applyUrlParams({
            url: loadedUrl,
            surfacedProgram,
            isOneApp,
            enableGlpEducation,
        });

        window.open(urlWithParams, '_self');
    },
};

export default CommonUtils;
