import ProtoUtils from '../common/utilities/proto-utils';
import CommonUtils from '../common/utilities/common-utils';

const {
    getHeaders,
    Migration: {OneAppMigrationClient, GetOneAppMigrationInfoRequest},
} = ProtoUtils;

const getHeadersWithSessionUUID = () => {
    return {
        ...getHeaders(),
        'x-initial-request-uuid': CommonUtils.getSessionUUID(),
    };
};

const OneAppMigrationAPI = {
    getOneAppMigration() {
        const request = new GetOneAppMigrationInfoRequest();

        request.setName('user/liv/me');

        return OneAppMigrationClient.getOneAppMigrationInfo(
            request,
            getHeadersWithSessionUUID()
        );
    },
};

export default OneAppMigrationAPI;
