import {useState, useEffect} from 'react';
import {useTransLoader} from '@teladoc/fe-i18n';
import Button from '@teladoc/pulse/ui/Button';
import Banner from '@teladoc/pulse/ui/Banner';
import MixpanelUtils from '@teladoc/fe-ccm/ui/common/utilities/mix-panel';
import StorageUtils from '@livongo/utilities/system/storage';
import TeamsUtils from '../../utilities';
import {BANNER_SEEN_KEY} from '../config';
import css from './PrivacyBanner.scss';

const PrivacyBanner = () => {
    const {t} = useTransLoader('app');
    const [showBanner, setShowBanner] = useState(false);

    useEffect(() => {
        setShowBanner(!StorageUtils.get({key: BANNER_SEEN_KEY}));
    }, []);

    const dismissBanner = () => {
        MixpanelUtils.track({event: 'privacy.banner.dismiss'});
        StorageUtils.set({key: BANNER_SEEN_KEY, value: true});
        setShowBanner(false);
    };

    const onClick = () => {
        MixpanelUtils.track({event: 'privacy.banner.click'});
        TeamsUtils.selectTab('privacy');
    };

    return showBanner ? (
        <Banner
            className={css.banner}
            showDismiss
            onDismissClick={dismissBanner}
        >
            <div className={css.bannerContent}>
                <p>
                    {t('banner.message')}
                    <span>
                        <Button className={css.bannerLink} onClick={onClick}>
                            {t('banner.link')}
                        </Button>
                    </span>
                </p>
            </div>
        </Banner>
    ) : null;
};

export default PrivacyBanner;
