import isEmpty from 'lodash/isEmpty';
import {useState, useCallback} from 'react';
import PropTypes from 'prop-types';
import {cnb} from 'cnbuilder';
import {useHistory, useLocation} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import ExternalPageLink from '@teladoc/fe-ccm/ui/common/external-page-link/ExternalPageLink';
import IconArrowLeftDefault from '@teladoc/pulse/icons/arrow-left-default.svg';
import HorizontalRule from '@teladoc/pulse/ui/HorizontalRule';
import ScaleConfirmationModal from '@teladoc/fe-ccm/ui/weight/common/scale-confirmation-modal/ScaleConfirmationModal';
import ApptentiveUtils from '@teladoc/fe-ccm/ui/common/apptentive/apptentive-utils';
import WeightAPI from '@teladoc/fe-ccm/ui/weight/weight-api';
import WeightUtils from '@teladoc/fe-ccm/ui/weight/weight-utils';
import UserUtils from '@teladoc/fe-ccm/ui/user/user-utils';
import useApi from '@teladoc/fe-ccm/ui/common/use-api';
import programs, {PROGRAM_STATUSES} from '@teladoc/fe-ccm/ui/programs';
import MixpanelUtils from '@teladoc/fe-ccm/ui/common/utilities/mix-panel';
import {
    coreRoutes,
    getPrimaryRouteById,
    getActiveRoutes,
} from '@teladoc/fe-ccm/ui/router/routes';
import Link from '@teladoc/fe-ccm/ui/router/Link';
import CommonUtils from '@teladoc/fe-ccm/ui/common/utilities/common-utils';
import {FEATURE_FLAG_NAMES} from '@teladoc/fe-ccm/ui/config';
import EnvironmentUtils from '@livongo/utilities/system/environment';
import useMounted from '@livongo/utilities/hooks/useMounted';
import {useFeatureFlagContext} from '@livongo/utilities/system/featureFlag';
import SubNavigation from './SubNavigation';
import css from './Navigation.scss';

const dashboard = getPrimaryRouteById('dashboard');
const weight = getPrimaryRouteById('weight');
const coachingID = 'coaching';
const reminderID = 'reminder';

const Navigation = ({isSubnavShowing, onItemClick}) => {
    const {t} = useTranslation();
    const {push} = useHistory();
    const {pathname} = useLocation();
    const {isMounted} = useMounted();
    const {featureFlags} = useFeatureFlagContext();
    const isSafari = EnvironmentUtils.isSafari();
    const {userLocale, programs: userPrograms} = useSelector(
        state => state.user
    );
    const {isOneApp, isScaleOnboardingDone} = useSelector(state => state.app);
    // Independently if members are still just REGISTERED into behavioral health
    // they can navigate to myStrength
    const hasAccessToMyStrength = userPrograms.some(
        ({id, status}) =>
            id === programs.behavioralHealth.id &&
            [PROGRAM_STATUSES.REGISTERED, PROGRAM_STATUSES.ACTIVE].includes(
                status
            )
    );
    const showBackToDashboard = UserUtils.willRender(dashboard.programSlugs);
    const [hasUsedScale, setHasUsedScale] = useState(false);
    const [showScaleModal, setShowScaleModal] = useState(false);
    const [showLanguageModal, setShowLanguageModal] = useState(false);
    const onDataLoaded = useCallback(
        ([allReadings]) => {
            if (isMounted) {
                setHasUsedScale(!isEmpty(allReadings));
            }
        },
        [isMounted]
    );
    const {isLoading} = useApi({
        api: [
            ...(UserUtils.willRender(weight.programSlugs)
                ? [WeightAPI.getReadings]
                : []),
        ],
        params: [
            {
                start: new Date(0),
                count: 1,
            },
        ],
        onLoad: onDataLoaded,
    });
    const onScaleModalToggle = () => {
        setShowScaleModal(show => !show);
    };
    const onLanguageModalToggle = () => {
        setShowLanguageModal(state => !state);
    };
    const onWeightClick = () => {
        if (isLoading) {
            return;
        }

        if (!showScaleModal) {
            MixpanelUtils.track({event: 'weight.scale.getstarted.clicked'});
        }

        WeightUtils.goToWeightPage({
            push,
            hasUsedScale,
            backToPath: pathname,
            isScaleOnboardingDone,
            onModalToggle: onScaleModalToggle,
        });
    };
    const onBehavioralHealthClick = evt => {
        const targetNew =
            evt.currentTarget.getAttribute('data-target') || '_blank';

        window.open(
            UserUtils.appendParamToUrl({
                url: process.env.MYSTRENGTH_URL,
                param: {
                    language: userLocale,
                },
            }),
            targetNew
        );
    };
    const onNavItemClick = evt => {
        const id = evt.currentTarget.getAttribute('data-id');
        const {mixpanelEvent, apptentiveEvent} = getPrimaryRouteById(id);

        if (mixpanelEvent) {
            MixpanelUtils.track({event: mixpanelEvent});
        }

        if (apptentiveEvent) {
            ApptentiveUtils.event(apptentiveEvent);
        }

        switch (id) {
            case 'weight':
                evt.preventDefault();
                onItemClick(evt);
                onWeightClick(evt);

                break;
            case 'behavioralHealth':
                evt.preventDefault();
                onItemClick(evt);
                onBehavioralHealthClick(evt);

                break;
            default:
                onItemClick(evt);
        }
    };
    const onSubnavItemClick = evt => {
        const routeId = evt.currentTarget.getAttribute('data-id');

        if (routeId) {
            const {mixpanelEvent} = getPrimaryRouteById(routeId);

            if (mixpanelEvent) {
                MixpanelUtils.track({event: mixpanelEvent});
            }
        }

        onItemClick(evt);
    };
    // We don't want to render Coaching in the nav for Legacy CCM
    let renderedNavRoutes = isOneApp
        ? getActiveRoutes(coreRoutes)
        : getActiveRoutes(coreRoutes).filter(({id}) => id !== coachingID);

    // Todo : will remove this once released Reminder feature
    renderedNavRoutes = CommonUtils.isFeatureEnabled(
        featureFlags,
        FEATURE_FLAG_NAMES.reminder,
        isOneApp
    )
        ? renderedNavRoutes
        : renderedNavRoutes.filter(({id}) => id !== reminderID);

    return (
        <nav
            className={cnb(css.root, {[css.isSubNav]: isSubnavShowing})}
            aria-label={t('sidebar.navigationLabel')}
        >
            <ul className={css.mainNav} role="navigation">
                {renderedNavRoutes.map(({id, path}) => {
                    return (
                        <li key={id} role="none">
                            <Link
                                className={cnb(css.navItem, css.navItemNeutral)}
                                activeClassName={css.navItemActive}
                                to={path}
                                data-id={id}
                                onClick={onNavItemClick}
                                role="link"
                                {...(isSafari && {tabIndex: 0})}
                            >
                                {t(`sidebar.navigation.${id}`)}
                                {id === 'behavioralHealth' && (
                                    <ExternalPageLink
                                        className={css.externalLink}
                                    />
                                )}
                            </Link>
                        </li>
                    );
                })}
            </ul>
            <div className={css.subNav}>
                {(showBackToDashboard || hasAccessToMyStrength) && (
                    <>
                        <ul className={css.backToDashboard}>
                            <li>
                                {showBackToDashboard && (
                                    <Link
                                        className={css.navItem}
                                        to={dashboard.path}
                                        data-id={dashboard.id}
                                        onClick={onSubnavItemClick}
                                        role="link"
                                        {...(isSafari && {tabIndex: 0})}
                                    >
                                        <IconArrowLeftDefault
                                            className={css.navItemIcon}
                                        />
                                        {t('sidebar.dashboard')}
                                    </Link>
                                )}
                                {!showBackToDashboard &&
                                    hasAccessToMyStrength && (
                                        <Link
                                            to="#"
                                            className={css.navItem}
                                            onClick={onBehavioralHealthClick}
                                            data-target="_self"
                                            role="link"
                                            {...(isSafari && {tabIndex: 0})}
                                        >
                                            <IconArrowLeftDefault
                                                className={css.navItemIcon}
                                            />
                                            {t('sidebar.myStrength')}
                                        </Link>
                                    )}
                            </li>
                        </ul>
                        <HorizontalRule className={css.hr} />
                    </>
                )}
                <SubNavigation
                    onItemClick={onSubnavItemClick}
                    showLanguageModal={showLanguageModal}
                    onLanguageModalToggle={onLanguageModalToggle}
                />
            </div>
            <ScaleConfirmationModal
                isOpen={showScaleModal}
                onRequestClose={onScaleModalToggle}
            />
        </nav>
    );
};

Navigation.propTypes = {
    isSubnavShowing: PropTypes.bool,
    onItemClick: PropTypes.func.isRequired,
};

export default Navigation;
