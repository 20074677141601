const KeyHandler = {
    // find the next child from the parent list of elements
    // when the user pressed on right arrow
    findNextFocus(index, obj) {
        const totalChildren = obj?.children?.length;
        const found =
            obj?.children[(index + 1) % totalChildren].querySelector(
                'button, a'
            );

        return found;
    },
    // find the next child from the parent list of elements
    // when the user pressed on left arrow
    findBackFocus(index, obj) {
        const totalChildren = obj?.children?.length;
        const found =
            obj?.children[
                (index - 1 + totalChildren) % totalChildren
            ].querySelector('button, a');

        return found;
    },
    // Find the index of the child from the parent list of elements
    // when user pressed on the down key
    findCurrentIndex(e, obj) {
        const name = e?.target?.name; // name of current button/link where handleKeyDown event called
        const listOfMenuItems = Array.from(obj?.children);

        return listOfMenuItems?.findIndex(
            child => child.querySelector('button, a')?.name === name
        );
    },
    // Set focus when a left/right key is pressed
    // Params: (e)event & (obj)object of ref (must be parent element)
    keyDown(e, obj, isExpandMenu) {
        try {
            const index = this.findCurrentIndex(e, obj);

            if (isExpandMenu) {
                // Allows up/down keys for expanded menu
                switch (e.key) {
                    case 'ArrowDown':
                        e.preventDefault();
                        this.findNextFocus(index, obj)?.focus();
                        break;
                    case 'ArrowUp':
                        e.preventDefault();
                        this.findBackFocus(index, obj)?.focus();
                        break;
                }
            } else {
                // allows left/right keys for menu bar
                switch (e.key) {
                    case 'ArrowRight':
                        this.findNextFocus(index, obj)?.focus();
                        break;
                    case 'ArrowLeft':
                        this.findBackFocus(index, obj)?.focus();
                        break;
                }
            }
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log('KeyHandler:', error);
        }
    },
};

export default KeyHandler;
