import PropTypes from 'prop-types';
import {useRef} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {cnb} from 'cnbuilder';
import FocusTrap from 'focus-trap-react';
import IconLanguageDefault from '@teladoc/pulse/icons/language-default.svg';
import Popover from '@teladoc/pulse/ui/Popover';
import Button from '@teladoc/pulse/ui/Button';
import {useI18nContext} from '@teladoc/pulse/ui/Context/i18n';
import KeyHandler from '@teladoc/fe-ccm/ui/common/utilities/key-handler';
import {userUpdate} from '../../user/user-actions';
import {USER_LOCALE_COOKIE, EVENT_PROPS} from '../../config';
import CommonUtils from '../utilities/common-utils';
import css from './LanguageSwitcher.scss';

const LanguageSwitcher = ({
    mobileView,
    transitionView,
    trackingData,
    keyDownPress = null,
}) => {
    const expandMenu = useRef(null);
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {scopedLangCodes} = useI18nContext();
    const {userLocale} = useSelector(state => state.user);

    const updateLanguage = locale => {
        CommonUtils.setCookie({
            key: USER_LOCALE_COOKIE,
            value: locale,
        });
        dispatch(
            userUpdate({
                userLocale: locale,
            })
        );

        if (trackingData) {
            trackingData(
                EVENT_PROPS.Global,
                2,
                EVENT_PROPS.LanguageOptions[locale]
            );
        }
    };

    const onLanguageToggle = isShowing => {
        if (isShowing && trackingData) {
            trackingData(EVENT_PROPS.Global, 1, EVENT_PROPS.Languages);
        }
    };

    return (
        <Popover
            hideOnContentClick
            hideOnOutsideClick
            onToggle={onLanguageToggle}
            trigger={
                <Button
                    className={cnb(
                        css.subNavMenu,
                        css.menuTextColor,
                        mobileView && css.mobileWrapper
                    )}
                    role="menuitem"
                    aria-label={t('oneapp.languageDescription', {
                        language: EVENT_PROPS?.LanguageOptions[userLocale],
                    })}
                    name="oneapp.language"
                    onKeyDown={keyDownPress}
                >
                    <span
                        className={cnb(
                            css.navItemText,
                            mobileView && css.mobileIconPosition,
                            transitionView && css.iconOutline
                        )}
                    >
                        <IconLanguageDefault
                            className={`${
                                mobileView ? css.mobileIcon : css.navIconLarge
                            } ${transitionView && css.iconAlign}`}
                        />
                        {!transitionView && (
                            <span className={css.navText}>
                                {t(`ui:languages.${userLocale}`).slice(0, 3)}
                            </span>
                        )}
                    </span>
                </Button>
            }
            content={
                <FocusTrap
                    focusTrapOptions={{
                        clickOutsideDeactivates: true,
                    }}
                >
                    <div>
                        <ul
                            role="menu"
                            className={cnb(
                                css.container,
                                css.root,
                                css.memberDropdown,
                                transitionView && css.transitionClass
                            )}
                            aria-label={t('oneapp.expanded')}
                            ref={expandMenu}
                        >
                            {scopedLangCodes.map((code, index) => (
                                <li role="none" key={code}>
                                    <button
                                        role="menuitem"
                                        onClick={() => updateLanguage(code)}
                                        className={cnb(
                                            css.navItem,
                                            css.dropdownItem,
                                            userLocale === code &&
                                                css.navItemActive
                                        )}
                                        aria-current={userLocale === code}
                                        name={`menu-item-${index}`}
                                        onKeyDown={e =>
                                            KeyHandler.keyDown(
                                                e,
                                                expandMenu?.current,
                                                true
                                            )
                                        }
                                    >
                                        <span className={css.navContainer}>
                                            <span
                                                className={css.navTextContainer}
                                            >
                                                {t(`ui:languages.${code}`)}
                                            </span>
                                        </span>
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </div>
                </FocusTrap>
            }
        />
    );
};

LanguageSwitcher.propTypes = {
    mobileView: PropTypes.bool,
    transitionView: PropTypes.bool,
    trackingData: PropTypes.func,
    keyDownPress: PropTypes.func,
};

export default LanguageSwitcher;
