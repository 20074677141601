import {useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import StorageUtils from '@livongo/utilities/system/storage';
import CommonUtils from '../common/utilities/common-utils';
import MixpanelUtils from '../common/utilities/mix-panel';

const TeladocSSOForm = ({data: {uri, parameters}}) => {
    const formRef = useRef(null);

    useEffect(() => {
        if (uri && parameters) {
            formRef.current.submit();
        }
    }, [uri, parameters]);

    useEffect(() => {
        const mobileSessionUuid = StorageUtils.get({key: 'mobile_sessionUuid'});
        const sessionUuid = CommonUtils.getSessionUUID();
        // Choose which UUID to use as the distinct ID
        const sessionId = mobileSessionUuid ? mobileSessionUuid : sessionUuid;

        // Track event with Mixpanel using session ID as distinct ID
        MixpanelUtils.track({
            event: 'post.response.usernavigatedtouri',
            properties: {'Session UUID': sessionId},
        });
    }, []);

    return (
        <form method="POST" action={uri} ref={formRef}>
            {Object.keys(parameters).map(key => (
                <input
                    key={key}
                    name={key}
                    type="hidden"
                    value={parameters[key]}
                />
            ))}
        </form>
    );
};

TeladocSSOForm.propTypes = {
    data: PropTypes.shape({
        uri: PropTypes.string.isRequired,
        parameters: PropTypes.object.isRequired,
    }),
};

export default TeladocSSOForm;
