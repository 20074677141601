/* eslint-disable no-underscore-dangle */
// This library works with the templates/mainfest.json

import {app, pages, authentication} from '@microsoft/teams-js';
import {ENV} from '../../config/env';

let environment = ENV.prod || 'prod';

if (process.env.ENVIRONMENT === 'local') {
    environment = ENV.local;
} else if (process.env.ENVIRONMENT === 'integration') {
    environment = ENV.integration;
} else if (process.env.ENVIRONMENT === 'preprod') {
    environment = ENV.preprod;
}

const IS_DEV = process.env.NODE_ENV === 'development';

class TeamsSingleton {
    OPTIONS = {
        tabsID: [],
        id: '',
        privacyURL: 'https://www.teladochealth.com/legal/privacy-policy/',
        manageTeamsURL:
            'https://support.microsoft.com/en-us/office/manage-notifications-in-teams-1cc31834-5fe5-412b-8edb-43fecc78413d',
    };

    constructor() {
        if (TeamsSingleton._instance) {
            throw new Error('Teams is already initialized');
        }
        TeamsSingleton._instance = this;
    }

    async getOptions() {
        const config = await import(
            /* webpackInclude: /\.json$/ */
            /* webpackChunkName: "config" */
            /* webpackMode: "eager" */
            /* webpackPrefetch: true */
            /* webpackPreload: true */
            `../../build/${environment}/manifest.json`
        );

        const values = {
            tabsID: config.staticTabs.map(tabObj =>
                tabObj.entityId ? tabObj.entityId : null
            ),
            id: config.id,
            privacyURL: config.developer.privacyUrl,
            manageTeamsURL:
                'https://support.microsoft.com/en-us/office/manage-notifications-in-teams-1cc31834-5fe5-412b-8edb-43fecc78413d',
        };

        this.OPTIONS = {...this.OPTIONS, ...values};

        return values;
    }

    init = () => {
        if (!app.isInitialized()) {
            return app
                .initialize()
                .then(() => {
                    app.notifySuccess(); // removes the teams app loader. TODO: remove this if not needed

                    return {isTeamsEnvironment: true};
                })
                .catch(error => {
                    if (IS_DEV) {
                        // eslint-disable-next-line no-console
                        console.error('[ERROR: MsTeams]', error);
                    }
                });
        }
    };

    selectTab = name => {
        if (!window) {
            throw new Error('something is wrong');
        }

        if (!name) {
            throw new Error(
                'please enter the tab you would like Teams to navigate to'
            );
        }

        if (!this.OPTIONS.tabsID.filter(id => id === name)) {
            throw new Error('Please use only the tab names in manifest.json');
        }

        const baseUrl = `https://${window.location.hostname}${
            window.location.port && `:${window.location.port}`
        }/${name}`;

        pages.navigateToApp({
            appId: this.OPTIONS.id,
            pageId: name,
            webUrl: encodeURI(baseUrl),
            id: name,
        });
    };

    setColorScheme = () => {
        const currentMode = document
            .querySelector('[data-theme="teladoc"]')
            .getAttribute('data-mode');

        app.getContext().then(context => {
            // context.theme can be default | dark | contrast
            // 'default' is how microsoft calls light mode, 'dark' is self explanatory!
            // 'contrast' === high contrast mode. We don't have it for MVP, so we can just keep the default Teladoc view in that case until then, I guess!
            const microsoftMode =
                context?.app?.theme === 'default'
                    ? 'light'
                    : context?.app?.theme;

            if (
                currentMode !== microsoftMode &&
                (microsoftMode === 'light' || microsoftMode === 'dark')
            ) {
                document
                    .querySelector('[data-theme="teladoc"]')
                    .setAttribute('data-mode', `${microsoftMode}`);
            }
        });
    };
}

const TeamsUtils = new TeamsSingleton();

TeamsUtils.getOptions();

export {app, pages, authentication};
export default TeamsUtils;
