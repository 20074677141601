import {DEVICE_CATEGORIES} from '../user-guides';

const {WEIGHT_SCALE, BLOOD_SUGAR_METER, BLOOD_PRESSURE_MONITOR} =
    DEVICE_CATEGORIES;

const REPORTS = {
    MULTI_CONDITION: {
        id: 'multiCondition',
        userVisibleKey: null,
        pdfUrl: 'healthsummaryreport',
        resultsUrl: null,
        mixpanelEvent: 'multicondition.summary.report',
    },
    BLOOD_PRESSURE_PERSONALIZED: {
        id: 'bloodPressurePersonalized',
        userVisibleKey: 'hasPersonalizedBPReport',
        pdfUrl: null,
        resultsUrl: '/blood-pressure/personalized-report',
        mixpanelEvent: 'bp.personalized.summary.report',
    },
};

const EXPORTS = {
    BLOOD_SUGAR: {
        id: 'bloodSugar',
        dbSlug: 'DIABETES',
        mixpanelEvent: 'bg.data.export.clicked',
    },
    BLOOD_PRESSURE: {
        id: 'bloodPressure',
        dbSlug: 'HYPERTENSION',
        mixpanelEvent: 'bp.data.export.clicked',
    },
    HEART_FAILURE: {
        id: 'heartFailure',
        dbSlug: 'HYPERTENSION', // Hearrt failure uses the same database slug as hypertension
        mixpanelEvent: null, // TODO: Add Mixpanel event
    },
};

export const PROGRAM_STATUSES = {
    PENDING_REGISTRATION: 'PENDING',
    ACTIVE: 'ACTIVE',
    INACTIVE: 'INACTIVE',
    OFFERED: 'OFFERED',
    USER_ACTION_REQUIRED: 'PENDING',
    REGISTERED: 'REGISTERED',
};

const baseConfigForWM = {
    slug: 'weightManagement',
    image: 'member-portal/programs/weight-management',
    deviceCategories: [WEIGHT_SCALE],
    reports: [REPORTS.MULTI_CONDITION],
    exports: null,
};

const baseConfigForDPP = {
    slug: 'prediabetes',
    image: 'member-portal/programs/prediabetes',
    deviceCategories: [WEIGHT_SCALE],
    reports: [REPORTS.MULTI_CONDITION],
    exports: null,
};

// NOTE: top level object 'keys' should be the same as the 'id' values
export default {
    bloodSugar: {
        id: 'bloodSugar',
        slug: 'diabetes',
        dbSlug: 'DIABETES',
        image: 'member-portal/programs/diabetes-legacy',
        deviceCategories: [BLOOD_SUGAR_METER],
        reports: [REPORTS.MULTI_CONDITION],
        exports: [EXPORTS.BLOOD_SUGAR],
        mixpanel: {
            getStarted: 'programs.bg.getstarted.clicked',
        },
    },
    bloodPressure: {
        id: 'bloodPressure',
        slug: 'hypertension',
        dbSlug: 'HYPERTENSION',
        image: 'member-portal/programs/hypertension',
        deviceCategories: [BLOOD_PRESSURE_MONITOR],
        reports: [REPORTS.MULTI_CONDITION, REPORTS.BLOOD_PRESSURE_PERSONALIZED],
        exports: [EXPORTS.BLOOD_PRESSURE],
        mixpanel: {
            getStarted: 'programs.bp.getstarted.clicked',
        },
    },
    heartFailure: {
        id: 'heartFailure',
        slug: 'heartFailure',
        dbSlug: 'HEART_FAILURE',
        image: 'member-portal/programs/heart-failure',
        deviceCategories: [WEIGHT_SCALE, BLOOD_PRESSURE_MONITOR],
        reports: [REPORTS.MULTI_CONDITION],
        exports: [EXPORTS.HEART_FAILURE],
        mixpanel: {
            // getStarted: 'programs.bp.getstarted.clicked', // TODO: Add mixpanel event
        },
    },
    weight: {
        id: 'weight',
        dbSlug: 'WEIGHT_MANAGEMENT',
        ...baseConfigForWM,
        mixpanel: {
            getStarted: 'programs.wm.getstarted.clicked',
        },
    },
    advancedWeight: {
        id: 'advancedWeight',
        slug: 'advancedWeight',
        dbSlug: 'ADV_WEIGHT_MANAGEMENT',
        image: 'member-portal/programs/weight-management',
        deviceCategories: [WEIGHT_SCALE],
        reports: [REPORTS.MULTI_CONDITION],
        exports: null,
        mixpanel: {
            getStarted: '', // TODO Add Mixpanel event
        },
    },
    comprehensiveWeight: {
        id: 'comprehensiveWeight',
        slug: 'comprehensiveWeight',
        dbSlug: 'COMPR_WEIGHT_CARE',
        image: 'member-portal/programs/weight-management',
        deviceCategories: [WEIGHT_SCALE],
        reports: [REPORTS.MULTI_CONDITION],
        exports: null,
        mixpanel: {
            getStarted: '', // TODO Add Mixpanel event
        },
    },
    awm: {
        id: 'awm',
        dbSlug: 'ADV_WEIGHT_MANAGEMENT',
        ...baseConfigForWM,
        mixpanel: {
            getStarted: 'programs.awm.getstarted.clicked',
        },
    },
    cwc: {
        id: 'cwc',
        dbSlug: 'COMPR_WEIGHT_CARE',
        ...baseConfigForWM,
        mixpanel: {
            getStarted: 'programs.cwc.getstarted.clicked',
        },
    },
    prediabetes: {
        id: 'prediabetes',
        dbSlug: 'PREDIABETES',
        ...baseConfigForDPP,
        mixpanel: {
            getStarted: 'programs.dpp.getstarted.clicked',
        },
    },
    comprehensivePrediabetes: {
        id: 'comprehensivePrediabetes',
        slug: 'comprehensivePrediabetes',
        dbSlug: 'COMPR_PREDIABETES_CARE',
        image: 'member-portal/programs/prediabetes',
        deviceCategories: [WEIGHT_SCALE],
        reports: [REPORTS.MULTI_CONDITION],
        exports: null,
        mixpanel: {
            getStarted: '', // TODO Add Mixpanel Event
        },
    },
    advancedPrediabetes: {
        id: 'advancedPrediabetes',
        slug: 'advancedPrediabetes',
        dbSlug: 'ADV_PREDIABETES',
        image: 'member-portal/programs/diabetes',
        deviceCategories: [WEIGHT_SCALE],
        reports: [REPORTS.MULTI_CONDITION],
        mixpanel: {
            getStarted: '', // TODO Add Mixpanel event
        },
    },
    adpp: {
        id: 'adpp',
        dbSlug: 'ADV_PREDIABETES',
        ...baseConfigForDPP,
        mixpanel: {
            getStarted: 'programs.adpp.getstarted.clicked',
        },
    },
    cdpc: {
        id: 'cdpc',
        dbSlug: 'COMPR_PREDIABETES_CARE',
        ...baseConfigForDPP,
        mixpanel: {
            getStarted: 'programs.cdpc.getstarted.clicked',
        },
    },
    behavioralHealth: {
        id: 'behavioralHealth',
        slug: 'behavioralHealth',
        dbSlug: 'BEHAVIORAL_HEALTH',
        image: 'member-portal/programs/behavioral-health',
        reports: null,
        exports: null,
        mixpanel: {
            getStarted: 'programs.bh.getstarted.clicked',
        },
    },
    chronicKidneyDisease: {
        id: 'chronicKidneyDisease',
        slug: 'chronicKidneyDisease',
        dbSlug: 'CHRONIC_KIDNEY_DISEASE',
        deviceCategories: [BLOOD_PRESSURE_MONITOR, WEIGHT_SCALE],
        image: 'member-portal/programs/kidney-health',
        reports: [REPORTS.MULTI_CONDITION, REPORTS.BLOOD_PRESSURE_PERSONALIZED],
        exports: [EXPORTS.BLOOD_PRESSURE],
        mixpanel: {
            getStarted: '', // TODO Add Mixpanel event
        },
    },
    deviceFlex: {
        id: 'deviceFlex',
        slug: 'deviceFlex',
        dbSlug: 'DIABETES_DEVICEFLEX',
        image: 'member-portal/programs/diabetes',
        reports: [REPORTS.MULTI_CONDITION],
        exports: [EXPORTS.BLOOD_SUGAR],
        mixpanel: {
            getStarted: '', // TODO Add Mixpanel event
        },
    },
};
