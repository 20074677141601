import {
    EDUCATION_SOURCE_PARAM,
    ONEAPP_PROFILE_SETTINGS_PATH,
} from '../../../config';
import CommonUtils from '../../../common/utilities/common-utils';

const CurriculumUtils = {
    PROGRAM_PREFERENTIAL_ORDER: [
        'comprehensivePrediabetes',
        'advancedPrediabetes',
        'prediabetes',
        'comprehensiveWeight',
        'advancedWeight',
        'weight',
    ],
    DPP_PROGRAMS: [
        'comprehensivePrediabetes',
        'advancedPrediabetes',
        'prediabetes',
    ],
    WEIGHT_PROGRAMS: ['comprehensiveWeight', 'advancedWeight', 'weight'],
    PREDIABETES_PARAM: '&program=PREDIABETES',
    WEIGHT_MANAGEMENT_PARAM: '&program=WEIGHT_MANAGEMENT',
    GLP_EDUCATION_ENABLED_PARAM: '&glp_education_enabled=true',
    // For Curriculum, there's a chance down the line that someone could be enrolled in multiple programs
    // that offer the lessons. So, we want to see what programs they have, and give preferential treatment
    // to some over others (basically, DPP over WM,  for now!)
    // For more info: https://confluence.teladoc.net/display/TDOC/GLP-1+Programs+Definition+and+Setup
    selectProgramToSurface(programs) {
        for (const topProgram of this.PROGRAM_PREFERENTIAL_ORDER) {
            if (topProgram in programs) {
                return topProgram;
            }
        }

        return null;
    },
    // currently, there's just two real types of curriculum that access with the URL params,
    // WEIGHT_MANAGEMENT and PREDIABETES.
    // so, we want any of the related programs to boil down to those params!
    getProgramParam({program, enableGlpEducation}) {
        if (!enableGlpEducation) {
            return null;
        } else if (this.DPP_PROGRAMS.includes(program)) {
            return this.PREDIABETES_PARAM;
        } else if (this.WEIGHT_PROGRAMS.includes(program)) {
            return this.WEIGHT_MANAGEMENT_PARAM;
        } else return null;
    },
    applyUrlParams({url, surfacedProgram, isOneApp, enableGlpEducation}) {
        const program =
            this.getProgramParam({surfacedProgram, enableGlpEducation}) || '';
        const source = EDUCATION_SOURCE_PARAM;
        const oneAppParams = isOneApp
            ? CommonUtils.generateOneAppParams(ONEAPP_PROFILE_SETTINGS_PATH)
            : '';

        if (!url) {
            return null;
        }

        // since the url returns with params of its own, we shouldn't have to worry about handling for whether the param
        // is marked with a `?` or a `&`
        return `${url?.getWebUrl()}${source}${program}${oneAppParams}`;
    },
};

export default CurriculumUtils;
